import React from 'react';

class BigStepPrivacyPolicy extends React.Component {

    render() {

        return (
            <div className={"luukitPrivacyPolicy"}>
                <embed
                    src="https://firebasestorage.googleapis.com/v0/b/firm23-23.appspot.com/o/assets%2Flegal%2FBigStep%20Privacy%20Policy.pdf?alt=media&token=0ac0b234-b077-447e-8f94-d01242402d1a"
                    type="application/pdf"
                    width="100%"
                    height="100%"
                />
            </div>
        );
    }
}

export default BigStepPrivacyPolicy;