import firebase from "firebase";
import _ from "lodash";

import {
    APPLICATIONS_FETCH_SUCCESS,
    CAREER_FIELD_ERROR,
    CAREER_INFO_UPDATED,
    CAREER_RESUME_UPLOADED, FETCH_JOB_APP_IDS_FAILED, FETCH_JOB_APP_IDS_SUCCESS, PUSH_CV,
    SAVE_RESUME_FAILED,
    SAVE_RESUME_SUCCESS,
    SUBMIT_RESUME
} from "./Types";

//variable declaration
let IDJobApp;


export const careerInfoUpdated = ({ prop, value}) => {
    return {
        type: CAREER_INFO_UPDATED,
        payload: { prop, value }
    }
};

export const careerResumeUploaded = (value) => {
    const fileName = value.name;

    //to delete previous image-----------**************
    /*
    // Create a reference to the file to delete
var desertRef = storageRef.child('images/desert.jpg');

// Delete the file
desertRef.delete().then(function() {
  // File deleted successfully
}).catch(function(error) {
  // Uh-oh, an error occurred!
});
     */

    return(dispatch) => {
        const uploadTask = firebase.storage().ref(`users/clients/careers/${fileName}`)
            .put(value);

        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            function(snapshot) {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                    default:
                }
            }, function(error) {

                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break;

                    case 'storage/canceled':
                        // User canceled the upload
                        break;

                    case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        break;
                    default:
                }
            }, function() {
                // Upload completed successfully, now we can get the download URL
                uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                    console.log('File available at', downloadURL);
                    dispatch({
                        type: CAREER_RESUME_UPLOADED,
                        payload: downloadURL
                    })
                });
            });
    };
};

export const submitJobApplication = ({ name, locationBased, email, phoneNumber, bio, profession, portfolio, resume,
                                         reasonForApplying, reasonForJoiningUs, whatYoudBring, compensation, marketReach, jobAppIDs }) => {
    let idx;
    let checkerX;
    let jobAppIDsArray = [];

    const appDate = new Date();


    function generateNo() {
        //you can change the range you want your keys to b in
        //Math.floor(Math.random() * (max - min + 1)) + min;
        let id = Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000;
        idx = id.toString();
    }

    function checkSimilar() {
        jobAppIDsArray = _.map(jobAppIDs, obj => { //assigning movies from user.uid to mIDs as objects
            return obj.IDJobApp;
        });

        checkerX = jobAppIDsArray.some(no => {
            return no === `${idx}IDJobApp`;
        });
    }

    function generateJobAppID() {
        while(checkerX !== false ) {
            generateNo();
            checkSimilar();
        }

        IDJobApp = `${idx}IDJobApp`;
    }

    generateJobAppID();

    return (dispatch) => {
        dispatch({ type: SUBMIT_RESUME });

        const data = JSON.stringify({ IDJobApp, name, locationBased, email, phoneNumber, bio, profession, portfolio, resume,
            reasonForApplying, reasonForJoiningUs, whatYoudBring, compensation, marketReach, appDate });

        fetch(`https://us-central1-firm23-23.cloudfunctions.net/saveResumeApplication `, {
            method: 'POST',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
            body: data
        }).then((response) => console.log(response))
            .then((response) => {
                console.log("Here's your response");
                console.log(response);
                dispatch({ type: SAVE_RESUME_SUCCESS });
            }).catch((error) => {
            console.log("Here's your error");
            console.log(error);
            saveJobAppFailed({error, dispatch});
        });
    };
};

const saveJobAppFailed = ({error, dispatch}) => {
    dispatch({
        type: SAVE_RESUME_FAILED
    });

    console.log(error)
};

export const fetchJobAppIDs = () => {

    return (dispatch) => {
        firebase.firestore().collection("assets").doc("jobAppIDs")
            .onSnapshot(function (doc) {
                if (doc.exists) {
                    let data = doc.data();

                    dispatch({
                        type: FETCH_JOB_APP_IDS_SUCCESS,
                        payload: data
                    });
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document @opening times!");
                }

            }, function (error) {
                dispatch({type: FETCH_JOB_APP_IDS_FAILED});
                console.log(error);
            });
    }
};

export const fieldError = () => {
    return {
        type: CAREER_FIELD_ERROR
    }
};

export const pushCVToState = (app) => {
    return {
        type: PUSH_CV,
        payload: app
    }
};

export const fetchApplications = () => {
    return (dispatch) => {
        firebase.firestore().collection('users').doc("clients").collection("public").doc("jobApps")
            .onSnapshot(function (doc) {
                if (doc.exists) {
                    let data = doc.data();
                    console.log(data);

                    dispatch({
                        type: APPLICATIONS_FETCH_SUCCESS,
                        payload: data
                    });
                }
                else {
                    console.log("doc with applications doesn't exist");
                }
            }, function (error) {
                console.log(error);
            })
    }
};