import React from 'react';
import { HashLoader } from 'react-spinners'

export const Spinner = () => {
    return (
        <div style={styles.spinnerStyle}>
            <HashLoader
                color={'#00a4a7'}
                loading={ true }

            />
        </div>
    );
};

const styles = {
    spinnerStyle: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: 8,
        paddingTop: 8
    }
};