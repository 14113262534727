import React from 'react';
import { connect } from "react-redux";
import _ from "lodash";
import { NavLink } from 'react-router-dom';
import {Spinner} from "./common";

//  action imports


class AdminPanel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            admin: this.props.admin,
            profile: this.props.profile,
        };

    }

    componentWillMount() {

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.profile !== this.props.profile) {
            this.setState({ profile: nextProps.profile })
        }
    }


    renderJobApplications() {
        //extract state
        const { profile } = this.state;

        if (!(_.isEmpty(profile))) {
            if (profile.role === "superAdmin") {
                return(
                    <div>
                        <NavLink to={"/jobApplications"}>Job Applications</NavLink>
                    </div>
                );
            }
        }
    }

    renderResearchResponses() {
        //extract state
        const { profile } = this.state;

        if (!(_.isEmpty(profile))) {
            if (profile.role === "admin" || profile.role === "superAdmin") {
                return(
                    <div>
                        <NavLink to={"/reposts"}>Research Responses</NavLink>
                    </div>
                );
            }
        }
    }

    render() {
        //extract state
        const { profile } = this.state;

        console.log({ profile });

        if (!(_.isEmpty(profile))) {
            return(
                <div>
                    { this.renderJobApplications() }
                    { this.renderResearchResponses() }
                </div>
            );
        } else {
            return <Spinner/>
        }
    }
}

const mapStateToProps = state => {
    const { profile } = state.admin;

    return {
        profile,
    }
};

export default connect(mapStateToProps, { })(AdminPanel);