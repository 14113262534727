import React from 'react';
import {isMobile} from 'react-device-detect';

class BavlaPrivacyPolicy extends React.Component {
    renderComponent() {
        const url = "https://firebasestorage.googleapis.com/v0/b/firm23-23.appspot.com/o/assets%2Flegal%2FBavla%20privacy%20policy.pdf?alt=media&token=3141d1c9-efa5-4884-9322-4c0009e90f7a";
        if (isMobile) {
            return (
                <embed
                    src={url}
                    type="application/pdf"
                    width="100%"
                    height="100%"
                />
            );
        } else {
            return (
                <embed
                    src={url}
                    type="application/pdf"
                    width="100%"
                    height="100%"
                />
            );
        }
    }

    render() {

        return (
            <div className={"luukitPrivacyPolicy"}>
                {this.renderComponent()}
            </div>
        );
    }
}

export default BavlaPrivacyPolicy;