import React from 'react';

class LalertPrivacyPolicy extends React.Component {

    render() {

        return (
            <div className={"luukitPrivacyPolicy"}>
                <embed
                    src="https://firebasestorage.googleapis.com/v0/b/firm23-23.appspot.com/o/assets%2Flegal%2FLalert%20Privacy%20Policy.pdf?alt=media&token=bf47a7a7-20cf-442f-9260-5fb9d48170e1"
                    type="application/pdf"
                    width="100%"
                    height="100%"
                />
            </div>
        );
    }
}

export default LalertPrivacyPolicy;