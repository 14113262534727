import React from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

//component imports
import DesignsLogos from "../DesignsLogos";
import DesignsWireFrames from "../DesignsWireFrames";
import DesignsGraphics from "../DesignsGraphics";

class PortfolioDesigns extends React.Component {
    render() {
        return (
            <div className={"portfolioContainer"}>

                <div className={"accordion_"}>
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading className={"accordion_title"}>
                                <AccordionItemButton>
                                    <div>
                                    <h3>WireFrames</h3>
                                    <div><p>At 23, we highly stress on beautiful and simple user interface designs that increase user engagements which translates to more revenues</p></div>
                                    </div>
                                </AccordionItemButton>
                            </AccordionItemHeading>

                            <AccordionItemPanel>
                                <DesignsWireFrames/>
                            </AccordionItemPanel>
                        </AccordionItem>


                        <AccordionItem>
                            <AccordionItemHeading className={"accordion_title"}>
                                <AccordionItemButton>
                                    <div>
                                    <h3>Logos</h3>
                                    <div><p>We draw logos that reveal your identity and act as strong marketing tools by making them easily memorable</p></div>
                                    </div>
                                </AccordionItemButton>
                            </AccordionItemHeading>

                            <AccordionItemPanel>
                                <DesignsLogos/>
                            </AccordionItemPanel>
                        </AccordionItem>


                        <AccordionItem>
                            <AccordionItemHeading className={"accordion_title"}>
                                <AccordionItemButton>
                                    <div>
                                    <h3>Graphical Collateral</h3>
                                    <div><p>From Posters to Brochures, we make sure the right info is highly visible amidst beautiful graphics that draw curiosity</p></div>
                                    </div>
                                </AccordionItemButton>
                            </AccordionItemHeading>

                            <AccordionItemPanel>
                                <DesignsGraphics/>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>

                </div>

            </div>
        );
    }
}

export default PortfolioDesigns;