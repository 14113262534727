//Career Types
export const CAREER_INFO_UPDATED = "career_info_updated";
export const CAREER_RESUME_UPLOADED = "career_resume_uploaded";
export const SUBMIT_RESUME = "submit_resume";
export const SAVE_RESUME_SUCCESS = "save_resume_success";
export const SAVE_RESUME_FAILED = "save_resume_failed";
export const FETCH_JOB_APP_IDS_SUCCESS = "fetch_job_app_ids_success";
export const FETCH_JOB_APP_IDS_FAILED = "fetch_job_app_ids_failed";
export const CAREER_FIELD_ERROR = "field_error";
export const APPLICATIONS_FETCH_SUCCESS = "applications_fetch_success";
export const PUSH_CV = "push_cv";

//  admin types
export const CREDENTIAL_UPDATE = "credential_update";
export const LOGIN_USER = "login_user";
export const LOGIN_USER_SUCCESS = "login_user_success";
export const LOGIN_USER_FAIL = "login_user_fail";
export const SIGNUP_USER_FAIL = "signup_user_fail";
export const LOGOUT_ADMIN = "logout_admin";

export const FETCH_PROFILE = "fetch_profile";
export const FETCH_PROFILE_SUCCESSFUL = "fetch_profile_successful";
export const FETCH_PROFILE_FAILED = "fetch_profile_failed";

// blog types
export const BLOG_FIELD_UPDATED = "blog_field_updated";
export const SAVE_BLOG_POST = "save_blog_post";
export const SAVE_BLOG_POST_SUCCESSFUL = "save_blog_post_successful";
export const SAVE_BLOG_POST_FAILED = "save_blog_post_failed";

export const FETCH_BLOG_POSTS = "fetch_blog_posts";
export const FETCH_BLOG_POSTS_SUCCESSFUL = "fetch_blog_posts_successful";
export const FETCH_BLOG_POSTS_FAILED = "fetch_blog_posts_failed";

export const DELETE_BLOG_POSTS = "delete_blog_posts";
export const DELETE_BLOG_SUCCESSFUL = "delete_blog_successful";
export const DELETE_BLOG_FAILED = "delete_blog_failed";

//Research types
export const RESEARCH_DETAILS_CHANGED = "research_details_changed";
export const SAVE_RESEARCH_DETAIL = "save_research_detail";
export const SAVE_RESEARCH_DETAIL_SUCCESSFUL = "save_research_detail_successful";
export const SAVE_RESEARCH_DETAIL_FAILED = "save_research_detail_failed";

//fetching Research details
export const FETCH_RESEARCH_DETAIL = "fetch_research_detail";
export const FETCH_RESEARCH_DETAIL_SUCCESSFUL = "fetch_research_detail_successful";
export const FETCH_RESEARCH_DETAIL_FAILED = "fetch_research_detail_failed";

export const ACCOUNT_DELETION = "account_deletion";
export const ACCOUNT_DELETION_SUCCESSFUL = "account_deletion_successful";
export const ACCOUNT_DELETION_FAILED = "account_deletion_failed";

