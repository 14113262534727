import React from 'react';

class DonaUserTermsAndConditions extends React.Component {

    render() {

        return (
            <div className={"luukitPrivacyPolicy"}>
                <embed
                    src="https://firebasestorage.googleapis.com/v0/b/firm23-23.appspot.com/o/assets%2Flegal%2FDona%20User%20Terms%20And%20Conditions.pdf?alt=media&token=7986c32c-66c6-4bcc-b442-9d4ddecd6910"
                    type="application/pdf"
                    width="100%"
                    height="100%"
                />
            </div>
        );
    }
}

export default DonaUserTermsAndConditions;