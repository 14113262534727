import React from 'react';
import { connect } from "react-redux";
import { message } from 'antd';

//component imports
import {Button, CardSection, Input} from "./common";

//action imports
import { careerInfoUpdated, careerResumeUploaded, submitJobApplication, fieldError } from "../actions/CareerActions";

class Careers extends React.Component {
    constructor(props) {
        super(props);

        this.onSubmitPress = this.onSubmitPress.bind(this);
        this.success = this.success.bind(this);
        this.error = this.error.bind(this);
    }

    success = () => {
        message.success('Successfully sent');
    };

    error = () => {
        message.error("There's an error");
    };

    onSubmitPress() {
        const { name, locationBased, email, phoneNumber, bio, profession, portfolio, resume,
            reasonForApplying, reasonForJoiningUs, whatYoudBring, compensation, marketReach, jobAppIDs} = this.props;

        if (name !== "" && locationBased !== "" && email !== "" && phoneNumber!== "" && bio !== "" && profession !== "" && resume !== "" && reasonForApplying !== "" &&
        reasonForJoiningUs !== "" && whatYoudBring !== "" && compensation !== "" && marketReach !== "" ) {
            this.props.submitJobApplication({ name, locationBased, email, phoneNumber, bio, profession, portfolio, resume,
                reasonForApplying, reasonForJoiningUs, whatYoudBring, compensation, marketReach, jobAppIDs });

            this.success();
        } else {
            this.error();
            this.props.fieldError();
        }
    }

    render() {
        return(
            <div>
                <div className={"pageHeading"}>
                    <h3 className={"mainTitle"}>Join a kickass team of <span className={"digital"}>digital</span> experts<br className={"break"}/> in the country. <span className="blinking-cursor">_</span></h3>
                </div>

                <div className={"responsiveLine"}>
                    <hr className="style11" />
                </div>

                <div className={"careersContent"}>
                    <div className={"careerHeading"}>
                        <h1>Apply for a job/internship</h1>
                    </div>

                    <div className={"careerBody"}>
                        <CardSection>
                            <Input
                                type={"text"}
                                label={'What is your name? *'}
                                placeholder={'Bruce Wayne'}
                                value={this.props.name}
                                onChange={  e => this.props.careerInfoUpdated({ prop: 'name', value: e.target.value })}
                                autoCorrect={false}
                            />
                        </CardSection>

                        <CardSection>
                            <Input
                                type={"text"}
                                label={'Where are you based? *'}
                                placeholder={'Africana, Dar es salaam'}
                                value={this.props.locationBased}
                                onChange={  e => this.props.careerInfoUpdated({ prop: 'locationBased', value: e.target.value })}
                                autoCorrect={false}
                            />
                        </CardSection>

                        <CardSection>
                            <Input
                                type={"email"}
                                label={'What is your email address? *'}
                                placeholder={'brucewayne@firm23.co.tz'}
                                value={this.props.email}
                                onChange={  e => this.props.careerInfoUpdated({ prop: 'email', value: e.target.value })}
                                autoCorrect={false}
                            />
                        </CardSection>

                        <CardSection>
                            <Input
                                type={"phone"}
                                label={'What is your phone number? *'}
                                placeholder={'0745 887 401'}
                                value={this.props.phoneNumber}
                                onChange={  e => this.props.careerInfoUpdated({ prop: 'phoneNumber', value: e.target.value })}
                                autoCorrect={false}
                                maxLength={10}
                            />
                        </CardSection>

                        <CardSection>
                            <p>What should we know about you? *</p>
                            <textarea
                                onChange={  e => this.props.careerInfoUpdated({ prop: 'bio', value: e.target.value }) }
                                rows={3} cols={100}
                                placeholder={"A short intro is a good start, but you may also want to mention your goals, special skills or experience…"}
                                name={'bio'}
                                value={this.props.bio}
                            />
                        </CardSection>

                        <CardSection>
                            <Input
                                type={"text"}
                                label={'What is your profession? *'}
                                placeholder={'Researcher or Programmer or Accountant etc.'}
                                value={this.props.profession}
                                onChange={  e => this.props.careerInfoUpdated({ prop: 'profession', value: e.target.value })}
                                autoCorrect={false}
                            />
                        </CardSection>

                        <CardSection>
                            <Input
                                type={"text"}
                                label={'Where can we see some of your work?'}
                                placeholder={'For Developers or Designers: GitHub or Dribble etc.'}
                                value={this.props.portfolio}
                                onChange={  e => this.props.careerInfoUpdated({ prop: 'portfolio', value: e.target.value })}
                                autoCorrect={false}
                            />
                        </CardSection>

                        <CardSection>
                            <div>
                                <p>Any file to share? *</p>
                            </div>
                            <Input
                                type={'file'}
                                accept={ "application/pdf" }
                                onChange={ e => this.props.careerResumeUploaded(e.target.files[0]) }
                                name={'file'}
                            />
                            <div>
                                <p id={"filePlaceholder"}>Maybe a portfolio or a resume in PDF format. Keep in mind we only allow 1 attachment.</p>
                            </div>
                        </CardSection>

                        <CardSection>
                            <p>Why are you looking for a job? *</p>
                            <textarea
                                onChange={  e => this.props.careerInfoUpdated({ prop: 'reasonForApplying', value: e.target.value }) }
                                rows={3} cols={80}
                                placeholder={"Maybe you don't have one to start with, but if you do, why do you want to change jobs?"}
                                name={'reasonForApplying'}
                                value={this.props.reasonForApplying}
                            />
                        </CardSection>

                        <CardSection>
                            <p>Why do you want to join us? *</p>
                            <textarea
                                onChange={  e => this.props.careerInfoUpdated({ prop: 'reasonForJoiningUs', value: e.target.value }) }
                                rows={3} cols={80}
                                placeholder={"Maybe you want to grow new skills or get to work on sh*t that matters."}
                                name={'reasonForJoiningUs'}
                                value={this.props.reasonForJoiningUs}
                            />
                        </CardSection>

                        <CardSection>
                            <p>What do you think you'd bring to the team? *</p>
                            <textarea
                                onChange={  e => this.props.careerInfoUpdated({ prop: 'whatYoudBring', value: e.target.value }) }
                                rows={3} cols={80}
                                placeholder={"You must have skills and experience that you think would be valuable to us."}
                                name={'whatYoudBring'}
                                value={this.props.whatYoudBring}
                            />
                        </CardSection>

                        <CardSection>
                            <p>What compensation are you expecting? *</p>
                            <textarea
                                onChange={  e => this.props.careerInfoUpdated({ prop: 'compensation', value: e.target.value }) }
                                rows={3} cols={80}
                                placeholder={"Include salary, benefits, and specify the currency."}
                                name={'compensation'}
                                value={this.props.compensation}
                            />
                        </CardSection>

                        <CardSection>
                            <Input
                                type={"text"}
                                label={'How did you hear about firm 23? *'}
                                placeholder={'Reference, Google, Instagram etc.'}
                                value={this.props.marketReach}
                                onChange={  e => this.props.careerInfoUpdated({ prop: 'marketReach', value: e.target.value })}
                                autoCorrect={false}
                            />
                        </CardSection>

                        <CardSection>
                            <p id={"careerConsent"}>By clicking submit I consent to sharing my application information with Firm 23 *</p>
                            <Button
                                text={"Submit"}
                                onPress={() => this.onSubmitPress()}
                            />
                            <p id={"careerFieldError"}>{this.props.careerFieldError}</p>
                        </CardSection>

                        <div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { name, locationBased, email, phoneNumber, bio, profession, portfolio, resume, reasonForApplying,
        reasonForJoiningUs, whatYoudBring, compensation, marketReach, jobAppIDs, careerFieldError } = state.career;

    return {
        name,
        locationBased,
        email,
        phoneNumber,
        bio,
        profession,
        portfolio,
        resume,
        reasonForApplying,
        reasonForJoiningUs,
        whatYoudBring,
        compensation,
        marketReach,
        jobAppIDs,
        careerFieldError
    }
};

export default connect(mapStateToProps, { careerInfoUpdated, careerResumeUploaded, submitJobApplication, fieldError })(Careers);