import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button, Select } from 'antd';

//action imports
import { sendAccountDeletionRequest } from "../../actions/AdminActions";
import {Spinner} from "../common";

class AccountDeletionForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			email: this.props.email,
			reason: this.props.reason,
			project: this.props.project,
			loading: this.props.loading
		};
	}

	componentWillReceiveProps(nextProps, nextContext) {
		if (nextProps !== this.props) {
			this.setState({
				email: nextProps.email,
				reason: nextProps.reason,
				project: nextProps.project,
				loading: nextProps.loading
			});
		}
	}

	handleInputChange = (name, value) => {
		this.setState({ [name]: value });
	}

	handleSubmit = () => {
		const { email, reason, project } = this.state;
		// Here, you would typically send the data to your server or email
		this.props.sendAccountDeletionRequest({ email, reason, project });
	}

	render() {
		const { Option } = Select;
		const { email, reason, project } = this.state;

		return (
			<div style={{ maxWidth: '500px', margin: '0 auto' }}>
				<h2 style={{ textAlign: 'center', fontFamily: 'myriadBold', marginTop: 20 }}>Account Deletion Request</h2>
				<Form onFinish={this.handleSubmit} layout="vertical">
					<Form.Item
						label="Select the project"
						name="project"
						rules={[{ required: true, message: 'Please select a project!' }]}
					>
						<Select
							placeholder="Select a project"
							onChange={value => this.handleInputChange('project', value)}
							value={project}
						>
							<Option value="bavla">Bavla</Option>
							<Option value="bigStep">BigStep</Option>
							<Option value="cbta">CBTA</Option>
							<Option value="dona">Dona</Option>
							<Option value="klassenScan">KlassenScan</Option>
							<Option value="lalert">Lalert</Option>
							<Option value="liveball">LiveBall</Option>
							<Option value="taxmy">Taxmy</Option>
							<Option value="theGrand">The Grand</Option>
							<Option value="afcl">AFCL Finance</Option>
							<Option value="nafasi">Nafasi</Option>
							<Option value="kingo">Kingo</Option>
						</Select>
					</Form.Item>

					<Form.Item
						label="Email"
						name="email"
						rules={[{ required: true, message: 'Please input your email!' }]}
					>
						<Input
							type="email"
							onChange={e => this.handleInputChange('email', e.target.value)}
							value={email}
						/>
					</Form.Item>

					<Form.Item
						label="Reason for deletion (optional)"
						name="reason"
					>
						<Input.TextArea
							onChange={e => this.handleInputChange('reason', e.target.value)}
							value={reason}
						/>
					</Form.Item>

					{
						this.state.loading ?
							<Spinner/> :
							<Form.Item>
								<Button type="primary" htmlType="submit">
									Submit Request
								</Button>
							</Form.Item>
					}
				</Form>
			</div>
		);
	}
}

const mapStateToProps = state => {
	const { loading, email, reason, project } = state.admin;

	return {
		loading,
		email,
		reason,
		project
	}
}

export default connect(mapStateToProps, { sendAccountDeletionRequest })(AccountDeletionForm);