import React, { Component } from 'react';
import { Modal } from "antd";
import {connect} from "react-redux"
import moment from "moment";


import ResearchViews from '../research/ResearchViews';

class ResearchViewCard extends Component {
    constructor(props){
        super(props);

        this.state = {
            research: this.props.research,
            visible:false,
        }

    }

    showModal = () => {
        this.setState({
            visible:true,
        })
    }

    handleOk = () => {
        this.setState({
            visible:false,
        })
    }

    handleCancel = () => {
        this.setState({
            visible:false,
        })
    }

    render() {
        const {
                researchID,
                email,
                businessOwner,
                ageGroup,
                softwareUser,
                city,
                socialMedia,
                newsSource,
                networkEventsAttendant,
                attendedEvents,
                wouldLikeToAttend,
                attendedEventsFrequently,
                willingToPayForSoftware,
                softwareToPay,
                preferredSoftwareDeveloper,date, } = this.state.research;

                const seconds = date.seconds;
                const dateObj = moment.unix(seconds);
                const dateString = dateObj.format("HH:mm | Do, dd MM YYYY");
        return (
            <div className={'viewCard'}>
                <div onClick={this.showModal}>
                    {email}
                </div>
                <Modal
                    visible={this.state.visible}
                    title="Research Answer"
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >   
                
                    <div>
                        <div>
                            <h1>ResearchID : {researchID}</h1>
                        </div>
                        <div>
                            <h>2.Are you a business owner?</h>
                            <p> = { businessOwner }</p>
                        </div>
                        <div>
                            <h>3.Your age group? </h>
                            <p> = { ageGroup }</p>
                        </div>
                        <div>
                            <h>4.Have you ever use a software product to simplify your work? </h>
                            <p> = { softwareUser }</p>
                        </div>
                        <div>
                            <h>5.Which city are your office based in? </h>
                            <p> = { city }</p>
                        </div>
                        <div>
                            <h>6.Which social media platform do you prefer the most </h>
                            <p> = { socialMedia }</p>
                        </div>
                        <div>
                             <h>7.Where do you usually get business news?</h>
                             <p> = { newsSource }</p>
                        </div>
                             <h>7a.Do you usually attend networking events? </h>
                             <p> = { networkEventsAttendant }</p>   
                        </div>
                        <div>
                            <h>7a.Do you usually attend networking events? </h>
                            <p> = { networkEventsAttendant }</p> 
                        </div>
                        <div>
                            <h>7b.On average how many networking events have you ever attended? </h>
                            <p> = { attendedEvents }</p>
                        </div>
                        <div>
                            <h>7c.Would you like to attend networking events in the future? </h>
                            <p> = {wouldLikeToAttend }</p>
                        </div>
                        <div>
                            <h>8.Which events do you attend more frequently </h>
                            <p> = { attendedEventsFrequently }</p>
                        </div>
                        <div>
                            <h>9.Are you willing to pay for custom software? </h>
                            <p> = { willingToPayForSoftware }</p>
                        </div>
                        <div>
                            <h>10.Up to how many custom software applications are you willing to pay for? </h>
                            <p> = { softwareToPay }</p> 
                        </div>
                        <div>
                            <h>11.Would you prefer your software development done by? </h>
                            <p> = { preferredSoftwareDeveloper }</p>
                        </div>
                        <div>
                            <p>Published At: { dateString }</p>
                        </div>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {

    }
}

export default connect(mapStateToProps)(ResearchViewCard)