import React from "react";
import { Link } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { animateScroll as scroll } from 'react-scroll'

//file imports

//trustee brands file imports
//import drmfLogo from '../assets/brands/drmf.png';
import allianceLogo from '../assets/brands/allianceFinance.png';
import arisLogo from '../assets/brands/aris.png';
import monarchyLogo from '../assets/brands/monarchy.png';
import tataLogo from '../assets/brands/TATA.png';
//import sodLogo from '../assets/brands/sod.png';


//poster file imports
import darknessWithin from '../assets/posters/darknesswithin.png';
import faith from '../assets/posters/faith.png';
import farFromHome from '../assets/posters/farFromHome.png';

//mobile UI file imports
// import checkoutUI from '../assets/mockups/pomelorApp/checkOut.jpg';
// import homeScreenUI from '../assets/mockups/pomelorApp/homeScreen.jpg';
// import discoverUI from '../assets/mockups/pomelorApp/discover.jpg';
// import sendBillUI from '../assets/mockups/pomelorApp/sendBill.jpg';
// import restaurantUI from '../assets/mockups/pomelorApp/restaurantMenu.jpg';

//logos file imports
import liquidLogo from '../assets/brands/liquid.png';
import katchLogo from '../assets/brands/kaach.png';
import triangleLogo from '../assets/brands/triangle.png';
import veLogo from '../assets/brands/VE.png';
import eredisLogo from '../assets/brands/eredis.png';
import trustHavenLogo from '../assets/brands/trusthaven.png';
// import luukitLogo from '../assets/brands/luukit.png';
import pomelorLogo from '../assets/brands/Pomelor.png';
//import nanziLogo from '../assets/brands/nanzisCafeWhiteBg.png';
// import kgLogo from '../assets/brands/kg.png';
// import fsLogo from '../assets/brands/fs.png';
// import ejLogo from '../assets/brands/ej.png';
// import tdciLogo from '../assets/brands/tdci.png';
// import oneXLogo from '../assets/brands/1X.png';
// import tenLogo from '../assets/brands/10.png';


// file icon imports
//import arrowIcon from '../assets/icons/arrow.png';
//import leftQuote from '../assets/icons/leftQuote.png';
//import audiOutline from '../assets/icons/audi.png';

import plugIcon from '../assets/icons/dynamic.png';
import bulbIcon from '../assets/icons/static.png'

//-> mockups import
import luukitMac from '../assets/mockups/luukitmac.png';
import pomelorOnIphone from '../assets/mockups/iphone12Floating.png';

//-> attestant profile dp imports
import dpNgowi from '../assets/profile/allianceFrida.png';

class LandingPage extends React.Component {

    constructor(props) {
        super(props);

        this.handleScrollToTop = this.handleScrollToTop.bind(this);
        this.handleScrollToBottom = this.handleScrollToBottom.bind(this);
    }

    // trusted by presentation mode
    trustedBy() {
        return(
            <div className={"trusteeCompaniesBox"}>
                <div className={"trusteeCompaniesLogoBox"}>
                    <div><img src={allianceLogo} alt="trustee logo" /></div>
                </div>

                <div className={"trusteeCompaniesLogoBox"}>
                    <div><img src={tataLogo} alt="trustee logo" id={'tataImg'} style={{ width: '4rem', objectFit: 'contain'}}/></div>
                </div>

                <div className={"trusteeCompaniesLogoBox"}>
                    <div><img src={arisLogo} alt="trustee logo" /></div>
                </div>

                <div className={"trusteeCompaniesLogoBox"}>
                    <div><img src={monarchyLogo} alt="trustee logo" /></div>
                </div>
            </div>
        ) 
    };

    
    handleScrollToTop = () => {
        scroll.scrollToTop();
    };

    handleScrollToBottom = () => {
        scroll.scrollToBottom();
    };
    
    render() {
        return (
            <div className={"mainContainer"}>
                <header className="App-header">
                    <div className={"mainHeadingBox"}>
                        <div className={"mainHeading"}>
                            <h3 className={"mainTitle"}>We create & grow <span className={"digital"}>digital</span> products <br className={"break"}/>for the world's leading brands. <span className="blinking-cursor">_</span></h3>
                        </div>   
                    </div>
                </header>

                <div className={"downArrowIconBox"}>
                    <FontAwesomeIcon icon="chevron-circle-down" size="2x" onClick={this.handleScrollToBottom} className={"downArrowIcon"} />
                </div> 

                {/* <div>
                    <hr className="style11" />
                </div> */}

                {/* Software and Systems Engineering section */}
                <div className={"softwareEngineeringContainer"}>
                    <h4>Software & Systems Engineering</h4>
                    <div className={"maroonUnderline"}><hr /></div>

                    <div className={"softwareEngineeringBox"}>
                        <div className={"webApp"}>
                            <div className={"iconBox"}><img src={plugIcon} alt="plug connect icon" /></div>
                            <h5>Dynamic Softwares</h5>
                        </div>
                        <div className={"webApp"}>
                            <div className={"iconBox"}><img src={bulbIcon} alt="bulb icon" /></div>
                            <h5>Static Softwares</h5>
                        </div>
                    </div>

                    <img src={luukitMac} alt="luukit web pannel img" className={"systemDesignImage"} />
                </div>

                {/* Luukit screen */}
                {/* <div className={"luukitIconBox"}>
                    <div className={"iconBox"}><img src={luukitLogo} alt="plug connect icon" /></div>
                    <h5><FontAwesomeIcon icon="asterisk" size="1x" style={{color: '#9c3664'}} /> Screen from Luukit Web Panel</h5>
                </div> */}

                {/* Mobile App Section */}
                <div className={"mobileAppsContainer"}>
                    <div className={"mobileAppsBox"}>
                        <div className={"iphone"}>
                            <img src={pomelorOnIphone} alt="pomelor on iphone" />
                        </div>

                        <div className={"appPlatforms"}>
                            <h4>Mobile Apps</h4>
                            <div className={"maroonUnderline1"}><hr /></div>
                            <h5>We develop</h5>
                            
                            <div className={"appPlatformBox"}>
                                <div className={"iconBoxiOS"} >
                                    <FontAwesomeIcon icon={['fab', 'apple']} size="4x" />
                                </div>
                                <div><h5>iOS Apps</h5></div>
                            </div>
                            
                            <div className={"appPlatformBox"} >
                                <div className={"iconBoxAndroid"} >
                                    <FontAwesomeIcon icon={['fab', 'android']} size="4x" />
                                </div>
                                <div><h5>Android Apps</h5></div>
                            </div>
                            
                            <div className={"appPlatformBox last"}>
                                <div className={"iconBox"}>
                                    <img src={pomelorLogo} alt="pomelor logo" />
                                </div>
                                <div><h5><FontAwesomeIcon icon="asterisk" size="1x" style={{color: '#9c3664'}} /> Screen from Pomelor Web Panel</h5></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"designContainer"}>
                    <div className={"designLogos"}>
                        <div>
                            <h4>Logos</h4>
                            <div className={"maroonUnderline2"}><hr /></div>
                            <div className={"logosContainer"}>
                                <div className={"brand1"}>
                                    <div className={"logoBox"}>
                                        <img src={liquidLogo} alt="logo" /><br/>
                                    </div>
                                </div>

                                <div className={"brand2"}>
                                    <div className={"logoBox"}>
                                        <img src={katchLogo} alt="logo" /><br/>
                                    </div>
                                </div>

                                <div className={"brand3"}>
                                    <div className={"logoBox"}>
                                        <img src={triangleLogo} alt="logo" /><br/>
                                    </div>
                                </div>

                                <div className={"brand4"}>
                                    <div className={"logoBox"}>
                                        <img src={veLogo} alt="logo" /><br/>
                                    </div>
                                </div>

                                <div className={"brand5"}>
                                    <div className={"logoBox"}>
                                        <img src={eredisLogo} alt="logo" /><br/>
                                    </div>
                                </div>

                                <div className={"brand6"}>
                                    <div className={"logoBox"}>
                                        <img src={trustHavenLogo} alt="logo" /><br/>
                                    </div>
                                </div>
                            </div>
                            <div className={"viewMoreLogos"}>
                                <h4><Link to="/portfolio">View More <FontAwesomeIcon icon="long-arrow-alt-right" size="2x" style={{color: '#8d8d8d', paddingTop: '1rem'}} /></Link></h4>
                            </div>
                        </div>

                        <div>
                            <h4>Graphical Collateral</h4>
                            <div className={"maroonUnderline2"}><hr /></div>
                            <div className={"graphicBox"}>
                                <div className={"graphicalCollateralBox"}>
                                    <Carousel
                                        showThumbs={false}
                                        autoPlay={true}
                                        infiniteLoop={true}
                                        interval={5000}
                                        showArrows={false}
                                        showStatus={false}
                                        stopOnHover={true}
                                    >
                                        <div>
                                            <img src={faith} alt="faith poster"/>
                                        </div>
                                        <div>
                                            <img src={darknessWithin} alt="darkess within poster" />
                                        </div>
                                        <div>
                                            <img src={farFromHome} alt="far from home poster"/>
                                        </div>
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"dividerHashLineBox"}>
                    <hr className="style11" />
                </div>
            
                <div className={"trusteeTestimonialContainer"}>
                    <h4>Clientele</h4>
                    <div className={"maroonUnderline2"}><hr /></div>

                    <div className={"trusteeTestimonialBox"}>

                        <div className={"trusteeContainer"}>
                            {this.trustedBy()}
                        </div>

                        {/*<div className={"testimonialArea"}>*/}
                            {/*<h5>TESTIMONIALS</h5>*/}
                            {/*<div className={"testimonialBox"}>*/}
                                {/*<div className={"attestantDP"}>*/}
                                    {/*<img src={dpNgowi} alt="attestant display 4to" />*/}
                                    {/*<FontAwesomeIcon icon="quote-left" size="4x" color="#9c3664" />*/}
                                {/*</div>*/}

                                {/*<div className={"attestantName"}>*/}
                                    {/*<h6>Frida Ngowi</h6>*/}
                                    {/*<p>Alliance Finance</p>*/}
                                {/*</div>*/}

                                {/*<div className={"attestantTestimony"}>*/}
                                    {/*<p>*/}
                                        {/*For Five months of working with Firm 23, I've happened */}
                                        {/*to believe in the growth of technology in my country*/}
                                    {/*</p>*/}
                                {/*</div>*/}

                                {/*<div className={"attestantRatings"}>*/}
                                    {/*<FontAwesomeIcon icon="star" size="1x" color="#fab04b" />*/}
                                    {/*<FontAwesomeIcon icon="star" size="1x" color="#fab04b" />*/}
                                    {/*<FontAwesomeIcon icon="star" size="1x" color="#fab04b" />*/}
                                    {/*<FontAwesomeIcon icon="star" size="1x" color="#fab04b" />*/}
                                    {/*<FontAwesomeIcon icon="star-half-alt" size="1x" color="#fab04b" />*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className={"upArrowIconBox"}>
                    <FontAwesomeIcon icon="chevron-circle-up" size="2x" onClick={this.handleScrollToTop} className={"upArrowIcon"} />
                </div>
            </div>
        );
    }
}

export default LandingPage;