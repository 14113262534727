import React from 'react';

class KingoUserTermsAndConditions extends React.Component {

	render() {

		return (
			<div className={"luukitPrivacyPolicy"}>
				<embed
					src="https://firebasestorage.googleapis.com/v0/b/firm23-23.appspot.com/o/assets%2Flegal%2FKingo%20Terms%20and%20Conditions%20.pdf?alt=media&token=bdc8906f-fcee-4ecd-ae73-b3269171bd80"
					type="application/pdf"
					width="100%"
					height="100%"
				/>
			</div>
		);
	}
}

export default KingoUserTermsAndConditions;