import firebase from "firebase";
import { RESEARCH_DETAILS_CHANGED, SAVE_RESEARCH_DETAIL, SAVE_RESEARCH_DETAIL_SUCCESSFUL,
SAVE_RESEARCH_DETAIL_FAILED, FETCH_RESEARCH_DETAIL, FETCH_BLOG_POSTS_SUCCESSFUL, FETCH_RESEARCH_DETAIL_FAILED, FETCH_RESEARCH_DETAIL_SUCCESSFUL } from "./Types";


export const researchDetailsChanged = ({prop, value}) => {
    return {
        type: RESEARCH_DETAILS_CHANGED,
        payload: {prop, value}
    }
};

export const saveResearchMarket = ({ email,businessOwner,ageGroup,softwareUser,city,socialMedia,newsSource,
            networkEventsAttendant,attendedEvents,wouldLikeToAttend,attendedEventsFrequently,
            willingToPayForSoftware,softwareToPay,preferredSoftwareDeveloper}) => {

    const researchID = Math.random().toString(36).substring(6).toUpperCase();
    const date = new Date();

    return (dispatch) => {
        dispatch({ type: SAVE_RESEARCH_DETAIL });
        firebase.firestore().collection("ResearchPosts").doc(researchID)
            .set({ date, email,businessOwner,ageGroup,softwareUser,city,socialMedia,newsSource,networkEventsAttendant,attendedEvents,wouldLikeToAttend,
                   attendedEventsFrequently,willingToPayForSoftware,softwareToPay,preferredSoftwareDeveloper, researchID }, { merge: true })
            .then(() => {
                dispatch({ type: SAVE_RESEARCH_DETAIL_SUCCESSFUL });
                console.log("research market post save successful");
            }).catch(error => {
            dispatch({ type:SAVE_RESEARCH_DETAIL_FAILED });
            console.log(error);
            console.log("research market post save failed");
        })
    };
};

//fetch research details
export const fetchResearchDetail = () =>{
    return(dispatch) => {
        dispatch({type: FETCH_RESEARCH_DETAIL})
        let researchPosts = {}

        firebase.firestore().collection("ResearchPosts").onSnapshot(function(querySnapshot) {
            querySnapshot.forEach(function(doc){
                researchPosts[doc.id] = doc.data();
            });
            dispatch({
                type: FETCH_RESEARCH_DETAIL_SUCCESSFUL,
                payload: researchPosts
            })
        }, function(error) {
            console.log(error);
            dispatch({
                type: FETCH_RESEARCH_DETAIL_FAILED,
            })
        })
    }
}
