import React from "react";
import {isMobileOnly} from "react-device-detect";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

//component imports
import luukItMac from "../../assets/mockups/luukitmac.png";
import luukItLogo from "../../assets/mockups/luukitApp/LI_.png";

class PortfolioWeb extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mobile: false
        };

        this.renderComponent = this.renderComponent.bind(this);
    }

    componentWillMount() {
        this.isMobileDevice()
    }

    isMobileDevice() {
        console.log(isMobileOnly);
        if (isMobileOnly) {
            this.setState({ mobile: true })
        }
    };

    renderComponent() {
        if (this.state.mobile) {
            return (
                <div className={"portfolioContainer"}>
                    <div>

                        <div className={"portfolioLogoBox"}>
                            <img src={luukItLogo} className={"brandLogo"} alt="logo" /><br/>
                        </div>

                        <div className={"webMockUpBox"}>
                            <img src={luukItMac} className={"webMockUp"} alt="luuk it web mock up" /><br/>
                        </div>

                    </div>
                </div>
            )
        } else {
            return (
                <div className={"portfolioContainer"}>
                    <div className={"section"}>

                        <div className={"portfolioLogoBox"}>
                            <img src={luukItLogo} className={"brandLogo"} alt="logo" /><br/>
                        </div>

                        <div className={"webMockUpBox"}>
                            <img src={luukItMac} className={"webMockUp"} alt="luuk it web mock up" /><br/>
                        </div>

                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <div>
                <div className={"portfolioContainer"}>

                    <div className={"accordion_"}>
                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading className={"accordion_title"}>
                                    <AccordionItemButton>
                                        <div>
                                       <h3>LuukIt</h3>
                                      <div>
                                        <div className={"portfolioLogoBox"}>
                                            <img src={luukItLogo} className={"brandLogo"} alt="logo" /><br/>
                                        </div>
                                     </div>
                                        </div>
                                    </AccordionItemButton>
                                </AccordionItemHeading>

                                <AccordionItemPanel>
                                    <div className={"webMockUpBox"}>
                                        <img src={luukItMac} className={"webMockUp"} alt="luuk it web mock up" /><br/>
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>

                        </Accordion>

                    </div>

                </div>
            </div>
        );
    }
}

export default PortfolioWeb;