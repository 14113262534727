import firebase from 'firebase';

import {
    ACCOUNT_DELETION, ACCOUNT_DELETION_FAILED, ACCOUNT_DELETION_SUCCESSFUL,
    CREDENTIAL_UPDATE, FETCH_PROFILE, FETCH_PROFILE_FAILED, FETCH_PROFILE_SUCCESSFUL,
    LOGIN_USER, LOGIN_USER_FAIL, LOGIN_USER_SUCCESS, LOGOUT_ADMIN, SIGNUP_USER_FAIL
} from './Types';
import {message} from "antd";

export const credentialUpdate = ({prop, value }) => {
    return {
        type: CREDENTIAL_UPDATE,
        payload:  {prop, value }
    }
};

export const loginUser = ({ email, password, history }) => {
    return (dispatch) => {
        dispatch({ type: LOGIN_USER });

        firebase.auth().signInWithEmailAndPassword(email, password)
            .then((user) => {
                loginUserSuccess(dispatch, user);
                history.push('/admin-panel');
            })
            .catch(()=> loginUserFail(dispatch))
    };
};

export const signupUser = ({ email, password }) => {
    return (dispatch) => {
        dispatch({ type: LOGIN_USER});

        firebase.auth().createUserWithEmailAndPassword(email, password)
            .then((user) => {
                signUpUserSuccess( dispatch, user );
            })
            .catch(()=> signupUserFail(dispatch))
    };
};

const loginUserSuccess = ( dispatch, user ) => {
    dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: user
    });
};

const signUpUserSuccess = ( dispatch, user ) => {
    dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: user
    });
};

const loginUserFail = (dispatch) => {
    console.log('sign in failed');
    dispatch({
        type: LOGIN_USER_FAIL
    });
};

const signupUserFail = (dispatch) => {
    dispatch({
        type: SIGNUP_USER_FAIL
    });
};

export const logOutAdmin = () => {
    return {
        type: LOGOUT_ADMIN
    }
};

export const fetchProfile = () => {
    return (dispatch) => {
        dispatch({
            type: FETCH_PROFILE
        });
        const userID = firebase.auth().currentUser.uid;
        firebase.firestore().collection("users").doc("admins").collection(userID).doc("info")
            .onSnapshot(snapshot => {
                if (snapshot.exists) {
                    const data = snapshot.data();
                    dispatch({
                        type: FETCH_PROFILE_SUCCESSFUL,
                        payload: data
                    })
                }
            }, function (error) {
                console.log(error);
                dispatch({
                    type: FETCH_PROFILE_FAILED
                })
            })
    };
};

export const sendAccountDeletionRequest = ({ email, reason, project }) => {
    return async (dispatch) => {
        dispatch({ type: ACCOUNT_DELETION });
        const functionUrl = 'https://us-central1-firm23-23.cloudfunctions.net/addAccountDeletionRequest';

        try {
            const response = await fetch(functionUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'mode': 'no-cors'
                },
                body: JSON.stringify({ email, reason, project }),
            });

            if (!response.ok) {
                message.error("Error")
                dispatch({ type: ACCOUNT_DELETION_FAILED });
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            message.success("Request Received");
            dispatch({ type: ACCOUNT_DELETION_SUCCESSFUL });
            return response.json();
        } catch (error) {
            message.error("Error")
            dispatch({ type: ACCOUNT_DELETION_FAILED });
            console.error('Error sending account deletion request:', error);
            throw error;
        }
    }
}
