import React from 'react';

class NafasiUserTerms extends React.Component {

    render() {

        return (
            <div className={"luukitPrivacyPolicy"}>
                <embed
                    src="https://firebasestorage.googleapis.com/v0/b/firm23-23.appspot.com/o/assets%2Flegal%2FNafasi%20User%20Terms%20%26%20Conditions.pdf?alt=media&token=c4c35f1b-7944-40af-9dd7-48ca8635fada"
                    type="application/pdf"
                    width="100%"
                    height="100%"
                />
            </div>
        );
    }
}

export default NafasiUserTerms;