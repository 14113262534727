import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

//  action imports
import { credentialUpdate, loginUser } from "../actions/AdminActions";

//  component imports
import { EmailInput } from "./common/EmailInput";
import { PasswordInput } from "./common/PasswordInput";
import { Button } from "./common";

class AdminLandingPage extends React.Component {

    constructor(props) {
        super(props);

        this.onLoginPress = this.onLoginPress.bind(this);
    }

    onLoginPress() {
        console.log("login pressed");
        const { email, password, history } = this.props;
        this.props.loginUser({ email, password, history });
    }

    render() {
        return(
            <div className={"adminContainer"}>
                <div className={"adminHeader"}>
                    <h2>ADMIN LOG IN</h2>
                </div>

                <div>
                    <hr className="style11" />
                </div>

                <div className={"adminLoginContainer"}>
                    <EmailInput
                        placeholder={"champagne@firm23.co.tz"}
                        value={this.props.email}
                        onChange={e => this.props.credentialUpdate({ prop: 'email', value: e.target.value })}
                    />

                    <PasswordInput
                        placeholder={"******"}
                        value={this.props.password}
                        onChange={e => this.props.credentialUpdate({ prop: 'password', value: e.target.value })}
                    />

                    {
                        this.props.error ?
                            <div className={"adminPageErrorContainer"}>
                                <p>{this.props.error}</p>
                            </div> :
                            null
                    }

                    <div className={"adminBtnContainer"}>
                        <Button
                            text={'Login'}
                            onPress={ () => this.onLoginPress() }
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { email, password, error } = state.admin;

    return {
        email,
        password,
        error
    }
};

export default withRouter(connect(mapStateToProps, { credentialUpdate, loginUser })(AdminLandingPage));