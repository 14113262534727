import React from 'react';

class Firm23Fintech extends React.Component {

    render() {

        return (
            <div className={"luukItTermsAndConditions"}>
                <embed
                    src="https://firebasestorage.googleapis.com/v0/b/firm23-23.appspot.com/o/assets%2Fpublications%2FFintech.pdf?alt=media&token=407060c1-5cc4-4bfc-9cb5-28a394cbedf9"
                    type="application/pdf"
                    width="100%"
                    height="100%"
                />
            </div>
        );
    }
}

export default Firm23Fintech;