import {BLOG_FIELD_UPDATED, DELETE_BLOG_SUCCESSFUL, FETCH_BLOG_POSTS_SUCCESSFUL} from "../actions/Types";

const INITIAL_STATE = {
    title: "",
    author: "",
    article:"",
    blogPosts: {},
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case BLOG_FIELD_UPDATED:
            return { ...state, [action.payload.prop]: action.payload.value };


            //fetch reducers
        case FETCH_BLOG_POSTS_SUCCESSFUL:
            return { ...state, blogPosts: action.payload }

            //delete blog
        case DELETE_BLOG_SUCCESSFUL:
            return {...state, deleteblog: action.payload}
        default:
            return state;
    }
};