import React from 'react';
import { Radio } from 'antd';


export class WouldLike extends React.Component {

    render() {
        return (
            <div>
                <p>{this.props.label}</p>
                <div>
                    <Radio.Group onChange={this.props.onChange} value={this.props.value}>
                        <Radio value="yes">Yes</Radio>
                        <Radio value="no">No</Radio>
                        <Radio value="maybe">Maybe</Radio>
                    </Radio.Group>
                </div>
            </div>
        );
    }
}