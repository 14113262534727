import React from 'react';

class LalertPrivacyPolicy extends React.Component {

    render() {

        return (
            <div className={"luukitPrivacyPolicy"}>
                <embed
                    src="https://firebasestorage.googleapis.com/v0/b/firm23-23.appspot.com/o/assets%2Flegal%2FDona%20Privacy%20Policy.pdf?alt=media&token=63bc8950-664e-42bc-a8b3-52616f30e8a6"
                    type="application/pdf"
                    width="100%"
                    height="100%"
                />
            </div>
        );
    }
}

export default LalertPrivacyPolicy;