import React from 'react';

class NafasiPrivacyPolicy extends React.Component {

    render() {

        return (
            <div className={"luukitPrivacyPolicy"}>
                <embed
                    src="https://firebasestorage.googleapis.com/v0/b/firm23-23.appspot.com/o/assets%2Flegal%2FNafasi%20Privacy%20Policy.pdf?alt=media&token=e4169c21-dde4-4079-a6e4-dcb8c8637adb"
                    type="application/pdf"
                    width="100%"
                    height="100%"
                />
            </div>
        );
    }
}

export default NafasiPrivacyPolicy;