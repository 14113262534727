import React from "react";

//components imports
import TabView from "../TabView";

class Portfolio extends React.Component {
    render() {
        return (
            <div className={"portfolio"}>
                <div className={"pageHeading"}>
                    <h3 className={"mainTitle"}>Thoughts & rambling from a <span className={"digital"}>digital</span><br className={"break"}/>product team. <span className="blinking-cursor">_</span></h3>
                </div>

                <div className={"portfolioContent"}>
                    <TabView/>
                </div>
            </div>
        );
    }
}

export default Portfolio;