import React, { Component } from 'react';
import Tabs from 'react-web-tabs/lib/Tabs';
import Tab from 'react-web-tabs/lib/Tab';
import TabPanel from 'react-web-tabs/lib/TabPanel';
import TabList from 'react-web-tabs/lib/TabList';
import 'react-web-tabs/dist/react-web-tabs.css';
import { isMobileOnly } from "react-device-detect";
import TabsMob from 'muicss/lib/react/tabs';
import TabMob from 'muicss/lib/react/tab';

//component import
import PortfolioMobile from "./portfolio/PortfolioMobile";
import PortfolioWeb from "./portfolio/PortfolioWeb";
import PortfolioDesigns from "./portfolio/PortfolioDesigns";


class TabView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mobile: false
        };

        this.isMobileDevice = this.isMobileDevice.bind(this);
        this.renderComponent = this.renderComponent.bind(this);
    }

    componentWillMount() {
        this.isMobileDevice()
    }

    isMobileDevice() {
        console.log(isMobileOnly);
        if (isMobileOnly) {
            this.setState({ mobile: true })
        }
    };

    renderComponent() {
        if (this.state.mobile) {
            return (
                <div className={"mobileTabNav"}>
                    <TabsMob justified={true}>
                        <TabMob value="pane-1" label="Mobile">
                            <PortfolioMobile/>
                        </TabMob>

                        <TabMob value="pane-2" label="Web">
                            <PortfolioWeb/>
                        </TabMob>

                        <TabMob value="pane-2" label="Designs">
                            <PortfolioDesigns/>
                        </TabMob>
                    </TabsMob>
                </div>
            )
        } else {
            return (
                <Tabs defaultTab="vertical-tab-one" vertical>
                    <TabList
                        className="my-tablist"
                    >
                        <Tab tabFor="vertical-tab-one">Mobile</Tab>
                        <Tab tabFor="vertical-tab-two">Web</Tab>
                        <Tab tabFor="vertical-tab-three">Designs</Tab>
                    </TabList>
                    <TabPanel tabId="vertical-tab-one">
                        <PortfolioMobile/>
                    </TabPanel>
                    <TabPanel tabId="vertical-tab-two">
                        <PortfolioWeb/>
                    </TabPanel>
                    <TabPanel tabId="vertical-tab-three">
                        <PortfolioDesigns/>
                    </TabPanel>
                </Tabs>
            )
        }
    }



    render() {
        return (
            <div>
                { this.renderComponent() }
            </div>
        );
    }
}

export default TabView;