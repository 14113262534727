import React from 'react';

export class EmailInput extends React.Component {

    render() {
        return (
            <div className={'emailInputContainerStyle'}>
                <div className={'emailInputLabelStyle'}>
                    <h3>Email</h3>
                    {/*<img*/}
                        {/*className={'emailInputIconStyle'}*/}
                        {/*src={require('../../images/envelope.png')}*/}
                        {/*alt={'envelope'}*/}
                    {/*/>*/}
                </div>

                <div className={'emailInput_InputContainerStyle'}>
                    <input
                        type={'email'}
                        className={'emailInput_InputStyle'}
                        placeholder={this.props.placeholder}
                        onChange={this.props.onChange}
                        autoCorrect={false}
                        name={'emailInput'}
                        value={this.props.value}
                    />
                </div>
            </div>
        );
    }
}


