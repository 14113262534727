import {FETCH_RESEARCH_DETAIL_SUCCESSFUL, RESEARCH_DETAILS_CHANGED} from "../actions/Types";

const INITIAL_STATE = {
    //
    email: '',
    businessOwner: '',
    ageGroup: '',
    softwareUser: '',
    city: '',
    socialMedia: [],
    newsSource: [],
    networkEventsAttendant: '',
    attendedEvents: '',
    wouldLikeToAttend: '',
    attendedEventsFrequently: [],
    willingToPayForSoftware: '',
    softwareToPay: '',
    preferredSoftwareDeveloper: '',
    researchPosts: {},
}

const ResearchReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        //
        case RESEARCH_DETAILS_CHANGED:
            console.log(action.payload);
            return {...state, [action.payload.prop]: action.payload.value };

        case FETCH_RESEARCH_DETAIL_SUCCESSFUL:
            return {...state, researchPosts: action.payload}

        default:
        return state;
    }

};

export default ResearchReducer;