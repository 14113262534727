import React from 'react';
import {isMobile} from 'react-device-detect';

class AFCLPrivacyPolicy extends React.Component {
	renderComponent() {
		const url = "https://firebasestorage.googleapis.com/v0/b/firm23-23.appspot.com/o/assets%2Flegal%2FAFCL%20Privacy%20Policy.pdf?alt=media&token=de5962b9-99a0-4c3f-9084-ecacb513ac0c";
		if (isMobile) {
			return (
				<embed
					src={url}
					type="application/pdf"
					width="100%"
					height="100%"
				/>
			);
		} else {
			return (
				<embed
					src={url}
					type="application/pdf"
					width="100%"
					height="100%"
				/>
			);
		}
	}

	render() {

		return (
			<div className={"luukitPrivacyPolicy"}>
				{this.renderComponent()}
			</div>
		);
	}
}

export default AFCLPrivacyPolicy;