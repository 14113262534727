import React from 'react';

export const CardSection = (props) => {
  return (
      /*<div style={[styles.containerStyle, props.style]}>*/

      <div className={'cardSectionContainerStyle'}>
          {props.children}
      </div>
  );
};
