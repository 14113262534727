import React, { Component } from 'react';
import firebase from 'firebase';
import { BrowserRouter } from 'react-router-dom';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { 
  faAsterisk,
  faChevronCircleDown,
  faChevronCircleUp,
  faChevronLeft, 
  faChevronRight, 
  faCircle, 
  faLightbulb,  
  faLongArrowAltRight, 
  faMobile,
  faPlug,
  faQuoteLeft,
  faStar,
  faStarHalfAlt
} from '@fortawesome/free-solid-svg-icons';


import './App.css';
import "./MacOs.css";
import "./mui.css";
import 'antd/dist/antd.css';
import './ResponsiveApp.css';

//component imports
import Main from "./components/Main";

library.add(
  fab,
  faChevronLeft,
  faChevronRight,
  faMobile,
  faLightbulb,
  faPlug,
  faCircle,
  faChevronCircleDown,
  faChevronCircleUp,
  faLongArrowAltRight,
  faStar,
  faStarHalfAlt,
  faQuoteLeft,
  faAsterisk,
)

class App extends Component {
    // state = {
    //     user: ''
    // };

    componentWillMount() {
        //Initialize Firebase
        const config = {
            apiKey: "AIzaSyDTiryuodNZzwD28HNmBE_SSpm1uJi3yno",
            authDomain: "firm23-23.firebaseapp.com",
            databaseURL: "https://firm23-23.firebaseio.com",
            projectId: "firm23-23",
            storageBucket: "firm23-23.appspot.com",
            messagingSenderId: "755402755541",
            appId: "1:755402755541:web:d4793b3e17b358894e815c"
        };
        firebase.initializeApp(config);

        firebase.auth().onAuthStateChanged((user) => {
            // if (user) {
            //     this.setState({ user: 'user logged in'})
            // } else {
            //     this.setState({ user: 'no user logged in'})
            // }
        })
    }

  render() {
    return (
      <BrowserRouter>
          <Main/>
      </BrowserRouter>
    );
  }
}

export default App;
