import React from 'react';
import { Radio } from 'antd';


export class AgeGroup extends React.Component {

    render() {
        return (
            <div>
                <p>{this.props.label}</p>
                <div>
                    <Radio.Group onChange={this.props.onChange} value={this.props.value}>
                        <Radio value="belowThirty">Below 30</Radio>
                        <Radio value="thirtyOneToFortyFour">31-44</Radio>
                        <Radio value="fortyFiveToFiftyNine">45-59</Radio>
                        <Radio value="aboveSixty">60 and above</Radio>
                    </Radio.Group>
                </div>
            </div>
        );
    }
}