import React from 'react';

class UserGuidelines extends React.Component {
    render() {
        return (
            <div className={"userGuidelines"}>
                User Guidelines
            </div>
        );
    }
}

export default UserGuidelines;