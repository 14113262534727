import React from "react";

//component imports
// import pomelorLogo from '../assets/brands/pomelorSmoke.png';
// import luukItLogo from '../assets/brands/luukit.png';
// import nanziLogo from '../assets/brands/nanzisCafeWhiteBg.png';
// import forTheLoveOfBags from '../assets/brands/forTheLoveOfBags.png';
// import henley from '../assets/brands/henley.png';
// import kaach from '../assets/brands/kaach.png';
// import liquid from '../assets/brands/liquid.png';
// import RO from '../assets/brands/RO.png';
// import triangle from '../assets/brands/triangle.png';
// import trusthaven from '../assets/brands/trusthaven.png';
// import VE from '../assets/brands/VE.png';
// import hundred from '../assets/brands/100.png';
// import w_leaves from '../assets/brands/w_leaves.png';
// import aLogo from '../assets/brands/A.png';
// import curry from '../assets/brands/curry.png';
// import eredis from '../assets/brands/eredis.png';
// import mLogo from '../assets/brands/M.png';
// import zFlame from '../assets/brands/Z_flame.png';


import bidiLogo from '../assets/brands/bidi.png';
import ospotLogo from '../assets/brands/ospot.png';
import tdcLogo from '../assets/brands/tdc.png';
import eagleLogo from '../assets/brands/eagle.png';
import homewinesLogo from '../assets/brands/homewines.png';
import jjrLogo from '../assets/brands/jjr.png';
import nervLogo from '../assets/brands/nerv.png';
import skadeLogo from '../assets/brands/skade.png';


class DesignsLogos extends React.Component {

    render() {
        return (
            <div>
                <div className={"tabBrandsContainer"}>
                    <div className={"brand1"}>
                        <div className={"logoBox"}>
                            <img src={bidiLogo} className={"brandLogo"} alt="logo" /><br/>
                        </div>

                        <div className={"logoInfo1"}>
                            <p>Bidi</p>
                        </div>
                    </div>

                    <div className={"brand2"}>
                        <div className={"logoBox"}>
                            <img src={ospotLogo} className={"brandLogo"} alt="logo" /><br/>
                        </div>

                        <div className={"logoInfo2"}>
                            <p>Ospot</p>
                        </div>
                    </div>

                    <div className={"brand3"}>
                        <div className={"logoBox"}>
                            <img src={tdcLogo} className={"brandLogo"} alt="logo" /><br/>
                        </div>

                        <div className={"logoInfo3"}>
                            <p>Tdc</p>
                        </div>
                    </div>

                    <div className={"brand4"}>
                        <div className={"logoBox"}>
                            <img src={eagleLogo} className={"brandLogo"} alt="logo" /><br/>
                        </div>
                        <div className={"logoInfo4"}>
                            <p>Eagle</p>
                        </div>
                    </div>
                    
                    <div className={"brand1"}>
                        <div className={"logoBox"}>
                            <img src={homewinesLogo} className={"brandLogo"} alt="logo" /><br/>
                        </div>
                        <div className={"logoInfo1"}>
                            <p>Homewines</p>
                        </div>
                    </div>

                    <div className={"brand2"}>
                        <div className={"logoBox"}>
                            <img src={jjrLogo} className={"brandLogo"} alt="logo" /><br/>
                        </div>
                        <div className={"logoInfo2"}>
                            <p>John Junior</p>
                        </div>
                    </div>

                    <div className={"brand3"}>
                        <div className={"logoBox"}>
                            <img src={nervLogo} className={"brandLogo"} alt="logo" /><br/>
                        </div>
                        <div className={"logoInfo3"}>
                            <p>Nerv</p>
                        </div>
                    </div>

                    <div className={"brand4"}>
                        <div className={"logoBox"}>
                            <img src={skadeLogo} className={"brandLogo"} alt="logo" /><br/>
                        </div>
                        <div className={"logoInfo4"}>
                            <p>Skade</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DesignsLogos;