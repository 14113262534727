import React from 'react';
import { connect } from 'react-redux';

//  component import
import {CVCardSection} from './common/CVCardSection';

class CVPage extends React.Component {
    render() {
        const {name, locationBased, email, phoneNumber, bio, profession, portfolio, resume, reasonForApplying, reasonForJoiningUs,
            whatYoudBring, compensation, marketReach } = this.props.cv;
        return(
            <div>
                <div className={"CVPageTitle"}>
                    <h1>{`CV Page of ${name}`}</h1>
                </div>

                <CVCardSection>
                    <h3>Applicant Name</h3>
                    <p>{name}</p>
                </CVCardSection>

                <CVCardSection>
                    <h3>Location</h3>
                    <p>{locationBased}</p>
                </CVCardSection>

                <CVCardSection>
                    <h3>Email</h3>
                    <p>{email}</p>
                </CVCardSection>

                <CVCardSection>
                    <h3>Phone Number</h3>
                    <p>{phoneNumber}</p>
                </CVCardSection>

                <CVCardSection>
                    <h3>Bio</h3>
                    <p>{bio}</p>
                </CVCardSection>

                <CVCardSection>
                    <h3>Profession</h3>
                    <p>{profession}</p>
                </CVCardSection>

                <CVCardSection>
                    <h3>Portfolio</h3>
                    <p>{portfolio}</p>
                </CVCardSection>

                <CVCardSection>
                    <h3>Resume</h3>
                    <a href={resume} download target={"_blank"}><p>Click to download</p></a>
                </CVCardSection>

                <CVCardSection>
                    <h3>Reason For Applying</h3>
                    <p>{reasonForApplying}</p>
                </CVCardSection>

                <CVCardSection>
                    <h3>Reason For Joining Us</h3>
                    <p>{reasonForJoiningUs}</p>
                </CVCardSection>

                <CVCardSection>
                    <h3>What The Applicant Brings</h3>
                    <p>{whatYoudBring}</p>
                </CVCardSection>

                <CVCardSection>
                    <h3>Compensation</h3>
                    <p>{compensation}</p>
                </CVCardSection>

                <CVCardSection>
                    <h3>How Applicant Got Knowledge Of Us</h3>
                    <p>{marketReach}</p>
                </CVCardSection>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { cv } = state.career;

    return {
        cv
    }
};

export default connect(mapStateToProps)(CVPage);