import React from 'react';
import { connect } from "react-redux";
import _ from "lodash";
import { NavLink } from 'react-router-dom';
import Icon from '@ant-design/icons';
import moment from "moment";
import arraySort from "array-sort";

//  action imports
import { logOutAdmin} from "../../actions/AdminActions";
import { fetchApplications, pushCVToState} from "../../actions/CareerActions";
import {Spinner} from "../common";

class JobApplications extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            admin: this.props.admin,
            profile: this.props.profile,
            workApps: this.props.workApps,
        };

        this.onCVPress = this.onCVPress.bind(this);
        this.onLogOutPress = this.onLogOutPress.bind(this);
        this.renderClock = this.renderClock.bind(this);
        this.renderComponent = this.renderComponent.bind(this);
        this.renderDatedApps = this.renderDatedApps.bind(this);
        this.renderNoDateApps = this.renderNoDateApps.bind(this);
    }

    componentWillMount() {
        this.props.fetchApplications();
        this.setState({ admin: this.props.admin });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.admin !== this.props.admin) {
            this.setState({ admin: nextProps.admin })
        }

        if (nextProps.profile !== this.props.profile) {
            this.setState({ profile: nextProps.profile })
        }

        if (nextProps.workApps !== this.props.workApps) {
            this.setState({ workApps: nextProps.workApps })
        }
    }

    onLogOutPress() {
        this.props.logOutAdmin();
    }

    onCVPress(app) {
        this.props.pushCVToState(app);
    }

    renderClock(app) {
        //check if appDate exists in app
        if ("appDate" in app) {
            //app date is available
            //extract seconds
            let seconds;
            app.appDate.seconds ? seconds = app.appDate.seconds : seconds = app.appDate._seconds;
            const appDate = moment.unix(seconds);

            return <p className={"cvDate"}>{appDate.format("dddd, MMMM Do YYYY, HH:mm")}</p>

        } else {
            return <p className={"cvDate"}>No Date</p>;
        }
    }

    renderDatedApps(datedApps) {
        //{ dateNumber: { date: '', values: [] }, dateNnumber: { date: '', values: [] }}
        //check if dated apps object is not empty
        if (!(_.isEmpty(datedApps))) {
            //we have dated apps
            ///sort the dated apps
            const datedAppsArray = _.map(datedApps, date => date);
            const sortedDatedApps = arraySort(datedAppsArray, 'date', { reverse: true });
            //loop over all the dated app objects
            return(
                <div>
                    {
                        sortedDatedApps.map(date => {
                            //grab first app from date obj to display date
                            const objDate = date.date;
                            let seconds;
                            objDate.seconds ? seconds = objDate.seconds : seconds = objDate._seconds;
                            const appDate = moment.unix(seconds);
                            return(
                                <div>
                                    <h3 className={'dateTitle'}>{appDate.format("dddd, MMMM Do YYYY")}</h3>
                                    <div className={"dataCardsContainer"}>
                                        {
                                            date.values.map(app => {
                                                return (
                                                    <NavLink to={'/cv-page'}>
                                                        <div className={"appCard"} onClick={() => this.onCVPress(app)}>
                                                            <h3>{app.name}</h3>
                                                            <p>{app.profession}</p>
                                                            {this.renderClock(app)}
                                                        </div>
                                                    </NavLink>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            );
        } else {
            //we don't have any dated apps
            return(
                <div className={"noDataContainer"}>
                    <p>No dated CV's to display</p>
                </div>
            );
        }
    }

    renderNoDateApps(noDateArray) {
        return (
            <div>
                <h2>CV's Before 2020</h2>
                <div className={"dataCardsContainer"}>
                    {
                        noDateArray.map(app => {
                            return (
                                <NavLink to={'/cv-page'}>
                                    <div className={"appCard"} onClick={() => this.onCVPress(app)}>
                                        <h3>{app.name}</h3>
                                        <p>{app.profession}</p>
                                        {this.renderClock(app)}
                                    </div>
                                </NavLink>
                            )
                        })
                    }
                </div>
            </div>
        );
    }

    renderComponent() {
        const data = this.state.workApps;
        if (this.state.admin) {
            let dataArray = _.map(data, obj => {
                return obj;
            });

            //separate apps with date and ones with no date
            let datedApps = {};
            let noDateArray = [];

            dataArray.map(app => {
                //check if appDate exists in app
                if ("appDate" in app) {
                    //app date is available
                    let seconds;
                    app.appDate.seconds ? seconds = app.appDate.seconds : seconds = app.appDate._seconds;
                    const appDate = moment.unix(seconds);
                    const appDateNumber = appDate.format("DDMMYYYY");

                    //check if app date is already an object in datedApps
                    if (`${appDateNumber}` in datedApps) {
                        //date object exists
                        //grab app date number
                        const datedAppsArray = datedApps[`${appDateNumber}`].values;
                        datedAppsArray.push(app);

                        return datedApps[`${appDateNumber}`].values = datedAppsArray;
                    } else {
                        //date object is new
                        return datedApps[`${appDateNumber}`] = {
                            date: app.appDate,
                            values: [app]
                        };
                    }

                } else {
                    return noDateArray.push(app);
                }

            });

            ///const sortedDataArray = arraySort(dataArray, 'appData', {reverse: true});

            return (
                <div className={"adminPanelDataContainer"}>
                    <div className={"adminPanelHeader"}>
                        <h2>Submitted CV's</h2>
                        <div className={"adminPanelLogOutBtn"} onClick={() => this.onLogOutPress()} >
                            <Icon type={"logout"} theme="outlined" style={{ color: "#fff" }} />
                        </div>
                    </div>
                    <div>
                        {this.renderDatedApps(datedApps)}
                        {this.renderNoDateApps(noDateArray)}
                    </div>
                </div>
            );
        } else {
            return (
                <div className={"noDataContainer"}>
                    <h2>Admin not logged in</h2>
                </div>
            );
        }
    }

    render() {
        //extract state
        const { profile } = this.state;

        if (!(_.isEmpty(profile))) {
            if (profile.role === "superAdmin") {
                return(
                    <div>
                        { this.renderComponent() }
                    </div>
                );
            }
        } else {
            return <Spinner/>
        }
    }
}

const mapStateToProps = state => {
    const { admin, profile } = state.admin;
    const { workApps } = state.career;

    return {
        admin,
        workApps,
        profile
    }
};

export default connect(mapStateToProps, { logOutAdmin, fetchApplications, pushCVToState })(JobApplications);