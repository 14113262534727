import React from 'react';

class NafasiOrganizationTerms extends React.Component {

    render() {

        return (
            <div className={"luukitPrivacyPolicy"}>
                <embed
                    src="https://firebasestorage.googleapis.com/v0/b/firm23-23.appspot.com/o/assets%2Flegal%2FNafasi%20Organization%20Terms%20%26%20Conditions.pdf?alt=media&token=d876f21d-e3ea-451f-b2b0-974b089e202a"
                    type="application/pdf"
                    width="100%"
                    height="100%"
                />
            </div>
        );
    }
}

export default NafasiOrganizationTerms;