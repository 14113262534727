import { combineReducers } from 'redux';

//reducer imports
import CareerReducer from "./CareerReducer";
import AdminReducer from "./AdminReducer";
import BlogReducer from "./BlogReducer";
import ResearchReducer from "./ResearchReducer";

export default combineReducers({
    career: CareerReducer,
    admin: AdminReducer,
    blog: BlogReducer,
    research: ResearchReducer,
})