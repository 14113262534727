import React from 'react';

class TheCitizen extends React.Component {

    render() {

        return (
            <div className={"luukItTermsAndConditions"}>
                <embed
                    src="https://firebasestorage.googleapis.com/v0/b/firm23-23.appspot.com/o/assets%2Fpublications%2Fcitizen_firm23.pdf?alt=media&token=491332c6-6037-463a-ba91-5f9f93649166"
                    type="application/pdf"
                    width="100%"
                    height="100%"
                />
            </div>
        );
    }
}

export default TheCitizen;