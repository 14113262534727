import React from 'react';
import { NavLink } from "react-router-dom"

class Publications extends React.Component {
    render() {
        return (
            <div className={"legal"}>
                <div className={"legal_termAndConditions"}>
                    <h1>NewsPapers</h1>
                    <NavLink to={'/the-citizen'}>
                        <h2>The Citizen</h2>
                    </NavLink>

                    <NavLink to={'/liveball-data'}>
                        <h2>LiveBall Data "The Guardian"</h2>
                    </NavLink>

                    <NavLink to={'/liveball-fantasy'}>
                        <h2>LiveBall Fantasy</h2>
                    </NavLink>

                    <NavLink to={'/firm23-fintech'}>
                        <h2>Firm 23 Fintech "The Guardian"</h2>
                    </NavLink>
                </div>

                <div className={"legal_privacyPolicy"}>
                    <h1>Impact</h1>
                    <NavLink to={"/liveball-impact"}>
                        <h2>LiveBall</h2>
                    </NavLink>
                </div>

                <div className={"legal_userGuidelines"}>
                    <h1>YouTube/Podcasts</h1>
                    <a href={"https://www.youtube.com/watch?v=RD7LbRhTJlc"} target="_blank">
                        <h2>LiveBall Sports Arena</h2>
                    </a>
                </div>
            </div>
        );
    }
}

export default Publications;