import React from 'react';

export const CVCardSection = (props) => {
    return (
        /*<div style={[styles.containerStyle, props.style]}>*/

        <div className={'CVCardSectionContainerStyle'}>
            {props.children}
        </div>
    );
};
