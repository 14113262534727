import React from "react";

//components imports
import pomelorWireFrame from "../assets/wireframes/pomelorWireframe.png";
import pomelorLogo from '../assets/brands/pomelorSmoke.png';

class DesignsWireFrames extends React.Component {
    render() {
        return (
            <div>
                <div className={"wireFrameLogoBox"}>
                    <img src={pomelorLogo} className={"brandLogo"} alt="logo" /><br/>
                </div>

                <div className={"wireFrameBox"}>
                    <img src={pomelorWireFrame} className={"wireFrame"} alt="pomelorWireFrame" /><br/>
                </div>
            </div>
        );
    }
}

export default DesignsWireFrames;