import React from "react";
import _ from "lodash";
//import moment from "moment";
import { connect } from "react-redux";
import ResearchViewCard from "../common/ResearchViewCard";

//action imports
import { fetchResearchDetail } from "../../actions/ResearchActions";

//css import
import '../common/ResearchMarket.css';

//Package imports
import arraySort from "array-sort";
import {Spinner} from "../common";

class ResearchViews extends React.Component {
    constructor(props){
      super(props);
      
      this.state = {
          researchPosts: this.props.researchPosts,
          profile: this.props.profile,
      }
    }

    componentWillMount(){
        this.props.fetchResearchDetail();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps.researchPosts !== this.props.researchPosts){
            this.setState({researchPosts: nextProps.researchPosts});
        }

        if(nextProps.profile !== this.props.profile){
            this.setState({profile: nextProps.profile});
        }
    }

    renderResponses(sortedArray) {
        //extract state
        const { profile } = this.state;

        if (profile.role === "admin" || profile.role === "superAdmin") {
            console.log("profile is eligible");
            return(
                <div className={'researchCard'}>
                    {
                        sortedArray.map(research => {
                            return(
                                <div>
                                    <ResearchViewCard research={research} />
                                </div>
                            );
                        })
                    }
                </div>
            );
        }
    }

    render() {
        //extracting the research posts
        const { researchPosts, date, profile } = this.state;
        const researchPostsArray = _.map(researchPosts, research => research);
        const sortedArray = arraySort(
            researchPostsArray, date , {reverse:true}
        );

        if (!(_.isEmpty(profile))) {
            return(
                <div className={'View'}>
                    <h1>VIEW MARKET RESEARCH POSTS.</h1>
                    <div>
                        { this.renderResponses(sortedArray) }
                    </div>
                </div>
            );
        } else {
            return <Spinner/>
        }
     }
}



const mapStateToProps = (state) => {
    const { researchPosts } = state.research;
    const { profile } = state.admin;

    return {
        researchPosts,
        profile
    }

}

export default connect(mapStateToProps, { fetchResearchDetail})(ResearchViews);
