import React from 'react';


class Events extends React.Component {
    render() {
        return(
            <div>
                <div className={"pageHeading"}>
                    <h3 className={"mainTitle"}>We host & attend events within the <br className={"break"}/><span className={"digital"}>digital</span> community. <span className="blinking-cursor">_</span></h3>
                </div>

                <div>
                    <hr className="style11" />
                </div>

                <div className={"eventsContent"}>
                    <h1>Coming Soon</h1>
                    
                </div>
            </div>
        );
    }
}

export default Events;