import React from 'react';
import {isMobile} from 'react-device-detect';

class KingoPrivacyPolicy extends React.Component {
	renderComponent() {
		const url = "https://firebasestorage.googleapis.com/v0/b/firm23-23.appspot.com/o/assets%2Flegal%2FKingo%20Privacy%20Policy.pdf?alt=media&token=d33249d1-904b-4a87-bf56-fb1d01bf8094";
		if (isMobile) {
			return (
				<embed
					src={url}
					type="application/pdf"
					width="100%"
					height="100%"
				/>
			);
		} else {
			return (
				<embed
					src={url}
					type="application/pdf"
					width="100%"
					height="100%"
				/>
			);
		}
	}

	render() {

		return (
			<div className={"luukitPrivacyPolicy"}>
				{this.renderComponent()}
			</div>
		);
	}
}

export default KingoPrivacyPolicy;