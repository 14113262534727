import React from "react";

class Expertise extends React.Component {
    render() {
        return (
            <div>
                <div className={"pageHeading"}>
                    <h3 className={"mainTitle"}>We combine tech, data & design to <br className={"break"}/>create <span className={"digital"}>digital</span> products your <br/>customers want. <span className="blinking-cursor">_</span></h3>
                </div>

                <div className={"responsiveLine"}>
                    <hr className="style11" />
                </div>

                <div className={"expertiseContent"}>
                    <div className={"expertiseContentTitle"}>
                        <h1>What we do</h1>
                    </div>

                    <div className={"expertiseContentDescription"}>
                        <div>
                            <p>
                                We use technology to drive business model innovation and growth. Most of our work revolves around omnichannel, membership and loyalty, digital transformation and often digital strategies for Africa. Our approach to digital is holistic, from the idea stage all the way to growing to tens of millions of users.
                            </p>
                        </div>

                        <div>
                            <h2>Design</h2>
                            <ul>
                                <li>Product Design</li>
                                <li>Prototyping</li>
                                <li>UI & UX Design</li>
                                <li>Graphics Design</li>
                            </ul>

                            <h2>Engineering</h2>
                            <ul>
                                <li>Architecture & Strategy</li>
                                <li>Mobile Development (iOS & Android)</li>
                                <li>Web Development</li>
                                <li>Networking</li>
                                <li>Hardware Development</li>
                            </ul>

                            <h2>Data Intelligence</h2>
                            <ul>
                                <li>Business Intelligence</li>
                                <li>Marketing Analytics</li>
                                <li>Customer Analytics</li>
                            </ul>
                                {/* <ul>
                                    <li style={{ "color":'black' }}>
                                        <a  style={{ "color":'blue' }} href="https://docs.google.com/forms/d/1oDsdI0NupzAQRAZiH8nxFD1azulpVWY6NoUwXar5DyM/prefill">Market Research
                                        </a>
                                     </li>
                                </ul> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Expertise;