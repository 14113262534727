import React from 'react';

export class PasswordInput extends React.Component {

    render() {
        return (
            <div className={'passwordInputContainerStyle'}>
                <div className={'passwordInputLabelStyle'}>
                    <h3>Password</h3>
                    {/*<img*/}
                        {/*className={'passwordInputIconStyle'}*/}
                        {/*src={require('../../images/key.png')}*/}
                        {/*alt={'key'}*/}
                    {/*/>*/}
                </div>

                <div className={'passwordInput_InputContainerStyle'}>
                    <input
                        type={'password'}
                        className={'passwordInput_InputStyle'}
                        placeholder={this.props.placeholder}
                        value={this.props.value}
                        onChange={this.props.onChange}
                        autoCorrect={false}
                    />
                </div>
            </div>
        );
    }
}



