import React from 'react';

class CBTAPrivacyPolicy extends React.Component {

    render() {

        return (
            <div className={"luukitPrivacyPolicy"}>
                <embed
                    src="https://firebasestorage.googleapis.com/v0/b/firm23-23.appspot.com/o/assets%2Flegal%2FCBTA%20Privacy%20Policy.pdf?alt=media&token=e40b12ff-2525-4509-b89e-42b57ee06e2d"
                    type="application/pdf"
                    width="100%"
                    height="100%"
                />
            </div>
        );
    }
}

export default CBTAPrivacyPolicy;