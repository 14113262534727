import React from 'react';

class LiveBallFantasy extends React.Component {

    render() {

        return (
            <div className={"luukItTermsAndConditions"}>
                <embed
                    src="https://firebasestorage.googleapis.com/v0/b/firm23-23.appspot.com/o/assets%2Fpublications%2FFantasy.pdf?alt=media&token=9d96fb67-9c51-4f9e-a51f-a6d4ae1384ad"
                    type="application/pdf"
                    width="100%"
                    height="100%"
                />
            </div>
        );
    }
}

export default LiveBallFantasy;