import React from 'react';

class LiveBallImpact extends React.Component {

    render() {

        return (
            <div className={"luukItTermsAndConditions"}>
                <embed
                    src="https://firebasestorage.googleapis.com/v0/b/firm23-23.appspot.com/o/assets%2Fpublications%2Fliveball_impact.pdf?alt=media&token=e946c392-b2be-4ed9-929a-0cbc8fe78a46"
                    type="application/pdf"
                    width="100%"
                    height="100%"
                />
            </div>
        );
    }
}

export default LiveBallImpact;