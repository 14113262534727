import {
    ACCOUNT_DELETION, ACCOUNT_DELETION_FAILED, ACCOUNT_DELETION_SUCCESSFUL,
    CREDENTIAL_UPDATE,
    FETCH_PROFILE_SUCCESSFUL,
    LOGIN_USER_FAIL,
    LOGIN_USER_SUCCESS,
    LOGOUT_ADMIN
} from "../actions/Types";

const INITIAL_STATE = {
    email: "",
    password: "",
    admin: false,
    error: "",
    profile: {},
    loading: false,
    reason: '',
    project: '',
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOGIN_USER_FAIL:
            return { ...state, error: "Authentication Error" };
        case LOGOUT_ADMIN:
            return { ...state, admin: false };
        case LOGIN_USER_SUCCESS:
            return { ...state, admin: true };
        case CREDENTIAL_UPDATE:
            return { ...state, [ action.payload.prop ]: action.payload.value };


        case FETCH_PROFILE_SUCCESSFUL:
            return { ...state, profile: action.payload };

        case ACCOUNT_DELETION:
            return { ...state, loading: true };
        case ACCOUNT_DELETION_SUCCESSFUL:
            return { ...state, loading: false, email: '', reason: '', project: '' };
        case ACCOUNT_DELETION_FAILED:
            return { ...state, loading: false };
        default:
            return state;
    }
};