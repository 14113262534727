import React from 'react';
import {connect} from 'react-redux';
import { Checkbox, Row, Radio } from 'antd';

//css import
import '../common/ResearchMarket.css';

//actions import
import {researchDetailsChanged, saveResearchMarket} from "../../actions/ResearchActions";

//components import
import {Button, Input} from "../common";
import {BooleanRadio} from "../common/BooleanRadio";
import {AgeGroup} from "../common/AgeGroup";
import {EventNumbers} from "../common/EventNumbers";
import {PreferredSoftwareDev} from "../common/PreferredSoftwareDev";
import {WouldLike} from "../common/WouldLike";


class ResearchMarket extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email: this.props.email,
            businessOwner: this.props.businessOwner,
            ageGroup: this.props.ageGroup,
            softwareUser: this.props.softwareUser,
            city: this.props.city,
            socialMedia: this.props.socialMedia,
            newsSource: this.props.newsSource,
            networkEventsAttendant: this.props.networkEventsAttendant,
            attendedEvents: this.props.attendedEvents,
            wouldLikeToAttend: this.props.wouldLikeToAttend,
            attendedEventsFrequently: this.props.attendedEventsFrequently,
            willingToPayForSoftware: this.props.willingToPayForSoftware,
            softwareToPay: this.props.softwareToPay,
            preferredSoftwareDeveloper: this.props.preferredSoftwareDeveloper,
        }

        this.onAgeGroup = this.onAgeGroup.bind(this);
        this.onAttendance = this.onAttendance.bind(this);
        this.onAttendedEvents = this.onAttendedEvents.bind(this);
        this.onAttendedFrequently = this.onAttendedFrequently.bind(this);
        this.onBusinessOwner = this.onBusinessOwner.bind(this);
        this.onNewsSource = this.onNewsSource.bind(this);
        this.onSelectDev = this.onSelectDev.bind(this);
        this.onSocialMedia = this.onSocialMedia.bind(this);
        this.onSoftwareUse = this.onSoftwareUse.bind(this);
        this.onWillingToPay = this.onWillingToPay.bind(this);
        this.onWouldLikeToAttend = this.onWouldLikeToAttend.bind(this);

        this.onSave = this.onSave.bind(this);

    }

    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps.email !== this.props.email){
            this.setState({email: nextProps.email})
        }

        if (nextProps.businessOwner !== this.props.businessOwner){
            this.setState({businessOwner: nextProps.businessOwner})
        }

        if (nextProps.ageGroup !== this.props.ageGroup){
            this.setState({ageGroup: nextProps.ageGroup})
        }

        if (nextProps.softwareUser !== this.props.softwareUser){
            this.setState({softwareUser: nextProps.softwareUser})
        }

        if (nextProps.city !== this.props.city){
            this.setState({city: nextProps.city})
        }

        if (nextProps.socialMedia !== this.props.socialMedia){
            this.setState({socialMedia: nextProps.socialMedia})
        }

        if (nextProps.newsSource !== this.props.newsSource){
            this.setState({newsSource: nextProps.newsSource})
        }

        if (nextProps.networkEventsAttendant !== this.props.networkEventsAttendant){
            this.setState({networkEventsAttendant: nextProps.networkEventsAttendant})
        }

        if (nextProps.attendedEvents !== this.props.attendedEvents){
            this.setState({attendedEvents: nextProps.attendedEvents})
        }

        if (nextProps.wouldLikeToAttend !== this.props.wouldLikeToAttend){
            this.setState({wouldLikeToAttend: nextProps.wouldLikeToAttend})
        }

        if (nextProps.attendedEventsFrequently !== this.props.attendedEventsFrequently){
            this.setState({attendedEventsFrequently: nextProps.attendedEventsFrequently})
        }

        if (nextProps.willingToPayForSoftware !== this.props.willingToPayForSoftware){
            this.setState({willingToPayForSoftware: nextProps.willingToPayForSoftware})
        }

        if (nextProps.softwareToPay !== this.props.softwareToPay){
            this.setState({softwareToPay: nextProps.softwareToPay})
        }

        if (nextProps.preferredSoftwareDeveloper !== this.props.preferredSoftwareDeveloper){
            this.setState({preferredSoftwareDeveloper: nextProps.preferredSoftwareDeveloper})
        }
    }

    onBusinessOwner = (value) => {
        //
        const a = value.target.value;
        this.props.researchDetailsChanged({prop: 'businessOwner', value: a})
    }

    onAgeGroup(value) {
        //
        this.props.researchDetailsChanged({prop: 'ageGroup', value})
    }

    onSoftwareUse(value) {
        //
        this.props.researchDetailsChanged({prop: 'softwareUser', value})
    }

    onSocialMedia(value) {
        //
        this.props.researchDetailsChanged({prop: 'socialMedia', value})
    }

    onNewsSource(value) {
        //
        this.props.researchDetailsChanged({prop: 'newsSource', value})
    }

    onAttendance(value) {
        //
        this.props.researchDetailsChanged({prop: 'networkEventsAttendant', value})
    }

    onAttendedEvents(value) {
        //
        this.props.researchDetailsChanged({prop: 'attendedEvents', value})
    }

    onWouldLikeToAttend(value) {
        //
        this.props.researchDetailsChanged({prop: 'wouldLikeToAttend', value})
    }

    onAttendedFrequently(value) {
        //
        this.props.researchDetailsChanged({prop: 'attendedEventsFrequently', value})
    }

    onWillingToPay(value) {
        //
        this.props.researchDetailsChanged({prop: 'willingToPayForSoftware', value})
    }

    onSelectDev(value) {
        //
        this.props.researchDetailsChanged({prop: 'preferredSoftwareDeveloper', value})
    }

    onSave() {
        const { email, businessOwner, ageGroup, softwareUser, city, socialMedia, newsSource, networkEventsAttendant, attendedEvents, wouldLikeToAttend, attendedEventsFrequently,
            willingToPayForSoftware, softwareToPay, preferredSoftwareDeveloper } = this.state;

        if( email !== "" && businessOwner !== "" && ageGroup !== "" && softwareUser !== "" && city !== "" &&
        socialMedia !== "" && newsSource !== "" && networkEventsAttendant !== "" && attendedEvents !== "" && wouldLikeToAttend !== "" && 
        attendedEventsFrequently !== "" && willingToPayForSoftware !== "" && softwareToPay !== "" && preferredSoftwareDeveloper !== ""){
        
        this.props.saveResearchMarket({ email,businessOwner,ageGroup,softwareUser,city,
        socialMedia,newsSource,networkEventsAttendant,attendedEvents,wouldLikeToAttend,
        attendedEventsFrequently,willingToPayForSoftware,softwareToPay,preferredSoftwareDeveloper})
        alert("Thank You.Your response has been sent successfully.")
        }else{
            alert("Please fill all required fields..");
            console.log("Please fill all required fields..");
        }
    }


    render() {
        return (
            <div className={"container"}>
                <h2>FIRM 23 MARKET SURVEY</h2>
                <p>This survey intends to find out on KAP (Knowledge, Attitudes, and Practice) 
                on software products ranging from software systems, web and mobile applications to website designs.
                The information provided will be confidential.</p>

                <div className={'row'}>
                   <div>
                       <Input
                           label={'1. Email'}
                           placeholder={'please fill your email..'}
                           onChange={e => this.props.researchDetailsChanged({prop: 'email', value: e.target.value})}
                           value={this.state.email}
                       />
                   </div>
                   <br />
                   <div>
                       <BooleanRadio
                           label={'2. Are you a business owner?'}
                           onChange={e => this.props.researchDetailsChanged({prop: 'businessOwner', value: e.target.value})}
                           value={this.state.businessOwner}
                       />
                   </div>
                   <br />
                   <div>
                       <AgeGroup
                           label={'3. Your age group?'}
                           onChange={e => this.props.researchDetailsChanged({prop: 'ageGroup', value: e.target.value})}
                           value={this.state.ageGroup}
                       />
                   </div>
                   <br />
                   <div>
                       <BooleanRadio
                           label={'4. Have you ever use a software product to simplify your work?'}
                           onChange={e => this.props.researchDetailsChanged({prop: 'softwareUser', value: e.target.value})}
                           value={this.state.softwareUser}
                       />
                   </div>
                   <br />
                   <div>
                       <Input
                           label={'5. Which city are your office based in'}
                           placeholder={'fill your city..'}
                           onChange={e => this.props.researchDetailsChanged({prop: 'city', value: e.target.value})}
                           value={this.state.city}
                       />
                   </div>
                   <br />
                    <div>
                        <p>6. Which social media platform do you prefer the most</p>
                        <Checkbox.Group style={{ width: '100%' }} onChange={this.onSocialMedia}>
                            <Row>
                                <Checkbox value="instagram">Instagram</Checkbox>
                            </Row>
                            <Row>
                                <Checkbox value="facebook">Facebook</Checkbox>
                            </Row>
                            <Row>
                                <Checkbox value="linkedIn">LinkedIn</Checkbox>
                            </Row>
                            <Row>
                                <Checkbox value="twitter">Twitter</Checkbox>
                            </Row>
                            <Row>
                                <Checkbox value="others">Others</Checkbox>
                            </Row>
                        </Checkbox.Group>
                        
                    </div>
                    <br />
                    <div>
                        <p>7. Where do you usually get business news?</p>
                        <Checkbox.Group style={{ width: '100%' }} onChange={this.onNewsSource}>
                            <Row>
                                <Checkbox value="newspapers">Newspapers/ Magazines</Checkbox>
                            </Row>
                            <Row>
                                <Checkbox value="blogs">Blogs</Checkbox>
                            </Row>
                            <Row>
                                <Checkbox value="socialMedias">Social medias</Checkbox>
                            </Row>
                            <Row>
                                <Checkbox value="others">Others</Checkbox>
                            </Row>
                        </Checkbox.Group>
                    </div>
                    <br />
                    <div>
                        <BooleanRadio
                            label={'7a. Do you usually attend networking events?'}
                            onChange={e => this.props.researchDetailsChanged({prop: 'networkEventsAttendant', value: e.target.value})}
                            value={this.state.networkEventsAttendant}
                        />
                    </div>
                    <br />
                    <div>
                        <EventNumbers
                            label={'7b. On average how many networking events have you ever attended?'}
                            onChange={e => this.props.researchDetailsChanged({prop: 'attendedEvents', value: e.target.value})}
                            value={this.state.attendedEvents}
                        />
                    </div>
                    <br />
                    <div>
                        <WouldLike
                            label={'7c. Would you like to attend networking events in the future?'}
                            onChange={e => this.props.researchDetailsChanged({prop: 'wouldLikeToAttend', value: e.target.value})}
                            value={this.state.wouldLikeToAttend}
                        />
                    </div>
                    <br />
                    <div>
                        <div>
                            <p>8. Which events do you attend more frequently</p>
                            <Checkbox.Group style={{ width: '100%' }} onChange={this.onAttendedFrequently}>
                                <Row>
                                    <Checkbox value="entrepreneurshipEvents">Entrepreneurship events</Checkbox>
                                </Row>
                                <Row>
                                    <Checkbox value="seminarsAndConferences">Seminars and conferences</Checkbox>
                                </Row>
                                <Row>
                                    <Checkbox value="productLaunchEvents">Product launch events</Checkbox>
                                </Row>
                                <Row>
                                    <Checkbox value="others">Others</Checkbox>
                                </Row>
                            </Checkbox.Group>
                        </div>
                    </div>
                    <br />
                    <div>
                        <BooleanRadio
                            label={'9. Are you willing to pay for custom software?'}
                            onChange={e => this.props.researchDetailsChanged({prop: 'willingToPayForSoftware', value: e.target.value})}
                            value={this.state.willingToPayForSoftware}
                        />
                    </div>
                    <br />
                    <div>
                        <Input
                            label={'10. Up to how many custom software applications are you willing to pay for?'}
                            onChange={e => this.props.researchDetailsChanged({prop: 'softwareToPay', value: e.target.value})}
                            value={this.state.softwareToPay}
                        />
                    </div>
                    <br />
                    <div>
                        <PreferredSoftwareDev
                            label={'11. Would you prefer your software development done by?'}
                            onChange={e => this.props.researchDetailsChanged({prop: 'preferredSoftwareDeveloper', value: e.target.value})}
                            value={this.state.preferredSoftwareDeveloper}
                        />
                    </div>
                </div>
                <br />
                <div>
                    <Button
                        text={'Submit'}
                        onPress={() => this.onSave()}
                    />
                </div>

            </div>
        );
    }
}

const mapStateToProps = (state) => {

    const { email, businessOwner, ageGroup, softwareUser, city, socialMedia, newsSource, networkEventsAttendant, attendedEvents, wouldLikeToAttend, attendedEventsFrequently,
            willingToPayForSoftware, softwareToPay, preferredSoftwareDeveloper, } = state.research;

    return {
        email,
        businessOwner,
        ageGroup,
        softwareUser,
        city,
        socialMedia,
        newsSource,
        networkEventsAttendant,
        attendedEvents,
        wouldLikeToAttend,
        attendedEventsFrequently,
        willingToPayForSoftware,
        softwareToPay,
        preferredSoftwareDeveloper,
    }
};

export default connect(mapStateToProps, {researchDetailsChanged, saveResearchMarket, })(ResearchMarket);