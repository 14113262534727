import React from 'react';

class LiveBallData extends React.Component {

    render() {

        return (
            <div className={"luukItTermsAndConditions"}>
                <embed
                    src="https://firebasestorage.googleapis.com/v0/b/firm23-23.appspot.com/o/assets%2Fpublications%2FData%20Startup.pdf?alt=media&token=18d5d90a-a1d5-4f5c-a56c-d459bc5a5f5f"
                    type="application/pdf"
                    width="100%"
                    height="100%"
                />
            </div>
        );
    }
}

export default LiveBallData;