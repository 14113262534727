import React from "react";
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import {isMobileOnly} from "react-device-detect";


//component imports
import pomelorLogo from "../../assets/brands/pomelorSmoke.png";
import luukItLogo from "../../assets/mockups/luukitApp/LI_.png";
import homeScreen from "../../assets/mockups/pomelorApp/homeScreen.jpg";
import discover from "../../assets/mockups/pomelorApp/discover.jpg";
import restaurantMenu from "../../assets/mockups/pomelorApp/restaurantMenu.jpg";
import checkOut from "../../assets/mockups/pomelorApp/checkOut.jpg";
import sendBill from "../../assets/mockups/pomelorApp/sendBill.jpg";

//file imports
import home from "../../assets/mockups/luukitApp/home.jpg";
import theaterProfile from "../../assets/mockups/luukitApp/theater_profile.jpg";
import movieProfile from "../../assets/mockups/luukitApp/movie_profile.jpg";
import confirmationPage from "../../assets/mockups/luukitApp/confirmation_page.jpg";
import invitePage from "../../assets/mockups/luukitApp/invite_page.jpg";

//liveball imports
import HOME from "../../assets/mockups/liveball/1-HOME.png";
import STATS from "../../assets/mockups/liveball/2-STATS.png";
import CLUBS from "../../assets/mockups/liveball/3-CLUBS.png";
import FANTASY from "../../assets/mockups/liveball/4-FANTASY.png";
import FANTASY2 from "../../assets/mockups/liveball/5-FANTASY-II.png";



class PortfolioMobile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mobile: false
        };

        this.renderComponent = this.renderComponent.bind(this);
    }

    componentWillMount() {
        this.isMobileDevice()
    }

    isMobileDevice() {
        console.log(isMobileOnly);
        if (isMobileOnly) {
            this.setState({ mobile: true })
        }
    };

    renderComponent() {
        if (this.state.mobile) {
            return (
                <div className={"portfolioContainer"}>
                    <div>
                        <div className={"accordion_"}>
                            <Accordion>
                                <AccordionItem>
                                    <AccordionItemHeading className={"accordion_title"}>
                                        <AccordionItemButton>
                                            <div>
                                                <h3>Pomelor</h3>
                                                <div className={"description_container"}>
                                                    <div className={"portfolioLogoBox"}>
                                                        <img src={pomelorLogo} className={"brandLogo"} alt="logo" /><br/>
                                                    </div>
                                                    {/*<p>A restaurant finder app where users discover information about restaurants around them including their menus with the option to order before hand and send bills to their friends</p>*/}
                                                </div>
                                            </div>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>

                                    <AccordionItemPanel>

                                        <CarouselProvider
                                            naturalSlideWidth={80}
                                            naturalSlideHeight={200}
                                            totalSlides={6}
                                            className={"carouselContainer"}
                                            isPlaying={true}
                                            touchEnabled={true}
                                        >
                                            <Slider
                                                className={"portfolioSlider"}
                                            >
                                                <Slide index={0}><div><img src={homeScreen} alt="home screen" /></div></Slide>
                                                <Slide index={1}><div><img src={discover} alt="discover" /></div></Slide>
                                                <Slide index={2}><div><img src={restaurantMenu} alt="restaurant menu" /></div></Slide>
                                                <Slide index={3}><div><img src={restaurantMenu} alt="restaurant menu" /></div></Slide>
                                                {/*<Slide index={4}><div><img src={checkOut} alt="check out" /></div></Slide>*/}
                                                {/*<Slide index={5}><div><img src={sendBill} alt="send bill" /></div></Slide>*/}
                                            </Slider>

                                        </CarouselProvider>
                                    </AccordionItemPanel>
                                </AccordionItem>


                                <AccordionItem>
                                    <AccordionItemHeading className={"accordion_title"}>
                                        <AccordionItemButton>
                                        <div>
                                            <h3>Luukit</h3>
                                            <div className={"description_container"}>
                                                <div className={"portfolioLogoBox"}>
                                                    <img src={luukItLogo} className={"brandLogo"} alt="logo" /><br/>
                                                </div>
                                                <p>A movie booking app that lets you get theater tickets and invite your friends while you're at it</p>
                                            </div>
                                        </div>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>

                                    <AccordionItemPanel>
                                        <CarouselProvider
                                            naturalSlideWidth={80}
                                            naturalSlideHeight={200}
                                            totalSlides={6}
                                            className={"carouselContainer"}
                                            isPlaying={true}
                                            touchEnabled={true}
                                        >
                                            <Slider
                                                className={"portfolioSlider"}
                                            >
                                                <Slide index={0}><div><img src={home} alt="home" /></div></Slide>
                                                <Slide index={1}><div><img src={theaterProfile} alt="theater profile" /></div></Slide>
                                                <Slide index={2}><div><img src={movieProfile} alt="movie profile" /></div></Slide>
                                                <Slide index={3}><div><img src={confirmationPage} alt="confirmation page" /></div></Slide>
                                                <Slide index={4}><div><img src={invitePage} alt="invite page" /></div></Slide>
                                            </Slider>

                                        </CarouselProvider>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading className={"accordion_title"}>
                                        <AccordionItemButton>
                                        <div>
                                            <h3>Liveball</h3>
                                            <div className={"description_container"}>
                                                <div className={"portfolioLogoBox"}>
                                                    <img src={luukItLogo} className={"brandLogo"} alt="logo" /><br/>
                                                </div>
                                                <p>A digital ecosystem acting as a base for live football and sports data science for the Tanzania Premier League</p>
                                            </div>
                                        </div>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>

                                    <AccordionItemPanel>
                                        <CarouselProvider
                                            naturalSlideWidth={60}
                                            naturalSlideHeight={200}
                                            totalSlides={6}
                                            className={"carouselContainer"}
                                            isPlaying={true}
                                            touchEnabled={true}
                                        >
                                            <Slider
                                                className={"portfolioSlider"}
                                            >
                                                <Slide index={0}><div><img src={HOME} alt="home" /></div></Slide>
                                                <Slide index={1}><div><img src={STATS} alt="Stats profile" /></div></Slide>
                                                <Slide index={2}><div><img src={CLUBS} alt="Clubs profile" /></div></Slide>
                                                <Slide index={3}><div><img src={FANTASY} alt="Fantasy page" /></div></Slide>
                                                <Slide index={4}><div><img src={FANTASY2} alt="Fantasy page" /></div></Slide>
                                             </Slider>

                                        </CarouselProvider>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion>
                        </div>

                    </div>
                </div>
            )
        } else {
            return (
                <div className={"portfolioContainer"}>

                    <div className={"accordion_"}>
                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading className={"accordion_title"}>
                                    <AccordionItemButton>
                                        <div>
                                            <h3>Pomelor</h3>
                                            <div className={"portfolioLogoBox"}>
                                            <img src={pomelorLogo} className={"brandLogo"} alt="logo" /><br/>
                                            </div>
                                        </div>
                                    </AccordionItemButton>
                                </AccordionItemHeading>

                                <AccordionItemPanel>
                                    <div className={"section"}>

                                        <div className={"carouselContainer"}>
                                            <Carousel
                                                showArrows={false}
                                                showStatus={false}
                                                showIndicators={false}
                                                infiniteLoop={true}
                                                width={"30%"}
                                                dynamicHeight={true}
                                                centerSlidePercentage={100}
                                            >
                                                <div><img src={homeScreen} alt="home screen" /></div>
                                                <div><img src={discover} alt="discover" /></div>
                                                <div><img src={restaurantMenu} alt="restaurant menu" /></div>
                                                {/*<div><img src={checkOut} alt="check out" /></div>*/}
                                                {/*<div><img src={sendBill} alt="send bill" /></div>*/}
                                            </Carousel>
                                        </div>


                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>


                            <AccordionItem>
                                <AccordionItemHeading className={"accordion_title"}>
                                   <AccordionItemButton>
                                       <div>
                                            <h3>LuukIt</h3>
                                            <div>
                                                <div className={"portfolioLogoBox"}>
                                                    <img src={luukItLogo} className={"brandLogo"} alt="logo" /><br/>
                                                </div>
                                            </div>
                                       </div>
                                   </AccordionItemButton>
                                </AccordionItemHeading>

                                <AccordionItemPanel>
                                    <div className={"section"}>

                                        <div className={"carouselContainer"}>
                                            <Carousel
                                                showArrows={false}
                                                showStatus={false}
                                                showIndicators={false}
                                                infiniteLoop={true}
                                                width={"30%"}
                                                dynamicHeight={true}
                                                centerSlidePercentage={100}
                                            >
                                                <div><img src={home} alt="home screen" /></div>
                                                <div><img src={theaterProfile} alt="theater profile" /></div>
                                                <div><img src={movieProfile} alt="movie profile" /></div>
                                                <div><img src={confirmationPage} alt="confirmation page" /></div>
                                                <div><img src={invitePage} alt="invite page" /></div>
                                            </Carousel>
                                        </div>


                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionItemHeading className={"accordion_title"}>
                                   <AccordionItemButton>
                                       <div>
                                            <h3>Liveball</h3>
                                            <div>
                                                <div className={"portfolioLogoBox"}>
                                                    <img src={pomelorLogo} className={"brandLogo"} alt="logo" /><br/>
                                                </div>
                                            </div>
                                       </div>
                                   </AccordionItemButton>
                                </AccordionItemHeading>

                                <AccordionItemPanel>
                                    <div className={"section"}>

                                        <div className={"carouselContainer"}>
                                            <Carousel
                                                showArrows={false}
                                                showStatus={false}
                                                showIndicators={false}
                                                infiniteLoop={true}
                                                width={"30%"}
                                                dynamicHeight={true}
                                                centerSlidePercentage={100}
                                            >
                                                <div><img src={HOME} alt="home screen" /></div>
                                                <div><img src={STATS} alt="Stats profile" /></div>
                                                <div><img src={CLUBS} alt="Clubs profile" /></div>
                                                <div><img src={FANTASY} alt="Fantasy page" /></div>
                                                <div><img src={FANTASY2} alt="Fantasy page" /></div>
                                            </Carousel>
                                        </div>


                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>

                        </Accordion>

                    </div>


                </div>
            )
        }
    }

    render() {
        return (
            <div>
                { this.renderComponent() }
            </div>
        );
    }
}

export default PortfolioMobile;