import React from "react";
import { connect } from "react-redux";
import { NavLink, Route, BrowserRouter } from "react-router-dom";
import Mailto from 'react-protected-mailto';
import moment from "moment";
import firebase from "firebase";


//file imports
import Portfolio from "./portfolio/Portfolio";
import Expertise from "./Expertise";
import logo from '../assets/23.png';
import Careers from "./Careers";
import Events from "./Events";
//import Blog from "./blog/Blog";
import AdminLandingPage from "./AdminLandingPage";

//social media icons imports
import facebookLogo from "../assets/graphics/facebook.png";
import twitterLogo from "../assets/graphics/twitter.png";
import instagramLogo from "../assets/graphics/instagram.png";

//action imports
import { fetchProfile } from "../actions/AdminActions";
import { fetchJobAppIDs } from "../actions/CareerActions";

//component imports
import AdminPanel from "./AdminPanel";
import JobApplications from "./jobApplications/jobApplications";
import CVPage from "./CVPage";
import Legal from "./Legal";
//import TermsAndConditions from "./TermsAndConditions";
import LuukItPrivacyPolicy from "./legal/LuukItPrivacyPolicy";
import UserGuidelines from "./UserGuidelines";
import LuukItTermsAndConditions from "./legal/LuukItTermsAndConditions";
import LandingPage from "./LandingPage";
import ResearchMarket from "./research/ResearchMarket";
import ResearchViews from "./research/ResearchViews";
import TaxmyPrivacyPolicy from "./legal/TaxmyPrivacyPolicy";
import BavlaPrivacyPolicy from "./legal/BavlaPrivacyPolicy";
import LiveballPrivacyPolicy from "./legal/LiveballPrivacyPolicy";
import LalertPrivacyPolicy from "./legal/LalertPrivacyPolicy";
import DonaPrivacyPolicy from "./legal/DonaPrivacyPolicy";
import BigStepPrivacyPolicy from "./legal/BigStepPrivacyPolicy";
import DonaUserTermsAndConditions from "./legal/DonaUserTermsAndConditions";
import TheGrandPrivacyPolicy from "./legal/TheGrandPrivacyPolicy";

import Publications from "./publications/Publications";
import TheCitizen from "./publications/TheCitizen";
import LiveBallImpact from "./publications/LiveBallImpact";
import LiveBallData from "./publications/LiveBallData";
import LiveBallFantasy from "./publications/LiveBallFantasy";
import Firm23Fintech from "./publications/Firm23Fintech";
import CBTAPrivacyPolicy from "./legal/CBTAPrivacyPolicy";
import NafasiPrivacyPolicy from "./legal/NafasiPrivacyPolicy";
import NafasiOrganizationTerms from "./legal/NafasiOrganizationTerms";
import NafasiUserTerms from "./legal/NafasiUserTerms";
import AFCLPrivacyPolicy from "./legal/AFCLPrivacyPolicy";
import AccountDeletionForm from "./links/AccountDeletionForm";
import KingoUserTermsAndConditions from "./legal/KingoUserTermsAndConditions";
import KingoPrivacyPolicy from "./legal/KingoPrivacyPolicy";


class Main extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            checkBox: false
        };

        this.onTap = this.onTap.bind(this);
    }

    componentWillMount() {
        this.props.fetchJobAppIDs();

        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                this.props.fetchProfile();
            }
        });
    }

    onTap() {
        this.setState({ checkBox: !this.state.checkBox})
    }

    render() {

        const year = moment().format("YYYY");

        return (
            <BrowserRouter>
                <div className="App">
                    <div className={"body"}>
                        <div className={"mainNav"}>
                            <div className={"logo"}>
                                <NavLink exact to={'/'}><img src={logo} className={"topLogoImage"} alt="logo" /></NavLink>
                            </div>

                            <nav role="navigation" className={"navigation"}>
                                <div id="menuToggle">
                                    {/*--
                                    A fake / hidden checkbox is used as click reciever,
                                    so you can use the :checked selector on it.
                                    --*/}
                                    <input type="checkbox" checked={this.state.checkBox} onChange={() => this.onTap()}/>

                                    {/* --
                                    Some spans to act as a hamburger.

                                    They are acting like a real hamburger,
                                    not that McDonalds stuff.
                                    -- */}
                                    <span> </span>
                                    <span> </span>
                                    <span> </span>

                                    {/* --
                                    Too bad the menu has to be inside of the button
                                    but hey, it's pure CSS magic.
                                    -- */}
                                    <ul id="menu">
                                        <NavLink activeClassName='selected' to={'/expertise'}><li onClick={() => this.onTap()}>Expertise</li></NavLink>
                                        <NavLink activeClassName='selected' to={'/portfolio'}><li onClick={() => this.onTap()}>Portfolio</li></NavLink>
                                        <NavLink activeClassName='selected' to={'/careers'}><li onClick={() => this.onTap()}>Careers</li></NavLink>
                                        <NavLink activeClassName='selected' to={'/events'}><li onClick={() => this.onTap()}>Events</li></NavLink>
                                        <NavLink activeClassName='selected' to={'/publications'}><li onClick={() => this.onTap()}>Publications</li></NavLink>
                                        {/* <NavLink activeClassName='selected' to={'/blog'}><li onClick={() => this.onTap()}>Blog</li></NavLink> */}
                                        {/*<NavLink activeClassName='selected' to={'/reposts'}><li onClick={() => this.onTap()}>RePosts</li></NavLink>*/}
                                        <NavLink activeClassName='selected' to={'/research'}><li onClick={() => this.onTap()}>Research</li></NavLink>
                                    </ul>
                                </div>
                            </nav>
                        </div>

                        <div className={'dashboardContent'}>
                            <Route exact path={'/'} component={ LandingPage }/>
                            <Route path={'/portfolio'} component={ Portfolio }/>
                            <Route path={'/expertise'} component={ Expertise }/>
                            <Route path={'/careers'} component={ Careers }/>
                            <Route path={'/events'} component={ Events }/>
                            {/* <Route path={'/blog'} component={ Blog }/> */}
                            <Route path={'/reposts'} component={ ResearchViews } />
                            <Route path={'/admin'} component={ AdminLandingPage } />
                            <Route path={'/admin-panel'} component={ AdminPanel } />
                            <Route path={'/cv-page'} component={ CVPage }/>
                            <Route path={'/legal'} component={ Legal }/>
                            <Route path={'/kingo-termsAndConditions'} component={ KingoUserTermsAndConditions }/>
                            <Route path={'/luukit-termsAndConditions'} component={ LuukItTermsAndConditions }/>
                            <Route path={'/dona-termsAndConditions'} component={ DonaUserTermsAndConditions }/>
                            <Route path={'/luukit-privacy-policy'} component={ LuukItPrivacyPolicy }/>
                            <Route path={'/taxmy-privacy-policy'} component={ TaxmyPrivacyPolicy }/>
                            <Route path={'/bavla-privacy-policy'} component={ BavlaPrivacyPolicy }/>
                            <Route path={'/liveball-privacy-policy'} component={ LiveballPrivacyPolicy }/>
                            <Route path={'/lalert-privacy-policy'} component={ LalertPrivacyPolicy }/>
                            <Route path={'/dona-privacy-policy'} component={ DonaPrivacyPolicy }/>
                            <Route path={'/bigstep-privacy-policy'} component={ BigStepPrivacyPolicy }/>
                            <Route path={'/thegrand-privacy-policy'} component={ TheGrandPrivacyPolicy }/>
                            <Route path={'/cbta-privacy-policy'} component={ CBTAPrivacyPolicy }/>
                            <Route path={'/nafasi-privacy-policy'} component={ NafasiPrivacyPolicy }/>
                            <Route path={'/afcl-privacy-policy'} component={ AFCLPrivacyPolicy }/>
                            <Route path={'/kingo-privacy-policy'} component={ KingoPrivacyPolicy }/>
                            <Route path={'/nafasi-organization-terms'} component={ NafasiOrganizationTerms }/>
                            <Route path={'/nafasi-user-terms'} component={ NafasiUserTerms }/>
                            <Route path={'/user-guidelines'} component={ UserGuidelines }/>
                            <Route path={'/research'} component={ ResearchMarket }/>
                            <Route path={'/jobApplications'} component={ JobApplications }/>

                            <Route path={'/publications'} component={ Publications }/>
                            <Route path={'/the-citizen'} component={ TheCitizen }/>
                            <Route path={'/liveball-impact'} component={ LiveBallImpact }/>
                            <Route path={'/liveball-data'} component={ LiveBallData }/>
                            <Route path={'/liveball-fantasy'} component={ LiveBallFantasy }/>
                            <Route path={'/firm23-fintech'} component={ Firm23Fintech }/>
                            <Route path={'/account-deletion'} component={ AccountDeletionForm }/>
                        </div>
                    </div>

                    <footer>
                        <div className={"info"}>
                            <div className={"infoName"}>
                                <h2>Firm 23</h2>
                            </div>

                            <div className={"infoDetails"}>
                                <div className={"infoAddress"}>
                                    <h3 className={"title"}>Dar Es Salaam</h3>
                                    <h3>Rainbow | Mbezi Beach</h3>
                                    <div className={"socialLinksContainer"}>
                                        <div>
                                            <a href={"https://twitter.com/firm_23"} target={"_blank"} id={"socialLinks"} rel={"noreferrer"}><img src={twitterLogo} className={"socialLogo"} alt="twitter logo" /></a>
                                            <a href={"https://www.instagram.com/firm.23/"} target={"_blank"} id={"socialLinks"} rel={"noreferrer"}><img src={instagramLogo} className={"socialLogo"} alt=" instagram logo" /></a>
                                            <a href={"https://www.facebook.com/Firm23-714599578933702/"} target={"_blank"} id={"socialLinks"} rel={"noreferrer"}><img src={facebookLogo} className={"socialLogo"} alt="facebook logo" /></a>
                                        </div>
                                    </div>
                                </div>

                                <div className={"infoEmails"}>
                                    <h3 className={"title"}>Get in Touch</h3>
                                    <Mailto tel='+255 22 261 7000' />
                                    <Mailto
                                        email='info@firm23.co.tz'
                                        headers={
                                            {subject:'Enquiry from the 23website'}
                                        }/>
                                    <Mailto
                                        email='business@firm23.co.tz'
                                        headers={
                                            {subject:'Enquiry from the 23website'}
                                        }/>
                                </div>
                            </div>
                        </div>

                        <div className={"footerCopyrightBox"}>
                            <div><p>Copyright © {year} Firm 23 Ltd. | <span><NavLink to={'/legal'}>Legal</NavLink></span> ⏤<span><NavLink to={'/admin'}>Admin</NavLink></span></p></div>
                        </div>
                    </footer>
                </div>
            </BrowserRouter>
        );
    }
}

export default connect(null, { fetchJobAppIDs, fetchProfile })(Main);