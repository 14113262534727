import React from 'react';

class LiveballPrivacyPolicy extends React.Component {

    render() {

        return (
            <div className={"luukitPrivacyPolicy"}>
                <embed
                    src="https://firebasestorage.googleapis.com/v0/b/firm23-23.appspot.com/o/assets%2Flegal%2FLiveBall%20Privacy%20policy.pdf?alt=media&token=e016a19a-cf36-4d63-b46b-42a948c178e6"
                    type="application/pdf"
                    width="100%"
                    height="100%"
                />
            </div>
        );
    }
}

export default LiveballPrivacyPolicy;