import {
    APPLICATIONS_FETCH_SUCCESS,
    CAREER_FIELD_ERROR,
    CAREER_INFO_UPDATED,
    CAREER_RESUME_UPLOADED,
    FETCH_JOB_APP_IDS_SUCCESS, PUSH_CV, SAVE_RESUME_SUCCESS
} from "../actions/Types";

const INITIAL_STATE = {
    name: "",
    locationBased: "",
    email: "",
    phoneNumber: "",
    bio: "",
    profession: "",
    portfolio: "",
    resume: "",
    reasonForApplying: "",
    reasonForJoiningUs: "",
    whatYoudBring: "",
    compensation: "",
    marketReach: "",
    cv: {},
    jobAppIDs: {},
    careerFieldError: "",
    workApps: {}
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PUSH_CV:
            return { ...state, cv: action.payload };
        case APPLICATIONS_FETCH_SUCCESS:
            return { ...state, workApps: action.payload };
        case CAREER_INFO_UPDATED:
            return { ...state, [action.payload.prop]: action.payload.value };
        case CAREER_RESUME_UPLOADED:
            return { ...state, resume: action.payload };
        case FETCH_JOB_APP_IDS_SUCCESS:
            return { ...state, jobAppIDs: action.payload };
        case CAREER_FIELD_ERROR:
            return { ...state, careerFieldError: "Fill all required fields *" };
        case SAVE_RESUME_SUCCESS:
            return { ...state, ...INITIAL_STATE };
        default:
            return state;
    }
}