import React from 'react';

class LuukItPrivacyPolicy extends React.Component {

    render() {

        return (
            <div className={"luukitPrivacyPolicy"}>
                <embed
                    src="https://firebasestorage.googleapis.com/v0/b/luukit-23.appspot.com/o/assets%2Flegal%2Fprivacypolicy%2Finfo%2FLuukIt%20Privacy%20Policy.pdf?alt=media&token=0d3bc692-7e28-48bf-b950-fc3c5172bc9b"
                    type="application/pdf"
                    width="100%"
                    height="100%"
                />
            </div>
        );
    }
}

export default LuukItPrivacyPolicy;