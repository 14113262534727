import React from 'react';

class LuukItTermsAndConditions extends React.Component {

    render() {

        return (
            <div className={"luukItTermsAndConditions"}>
                <embed
                    src="https://firebasestorage.googleapis.com/v0/b/luukit-23.appspot.com/o/assets%2Flegal%2Ftermsandconditions%2Finfo%2FLuukIt%20Terms%20%26%20Conditions.pdf?alt=media&token=c36a9bd9-ecc7-4a4c-bd32-4e26390f1357"
                    type="application/pdf"
                    width="100%"
                    height="100%"
                />
            </div>
        );
    }
}

export default LuukItTermsAndConditions;