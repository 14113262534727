import React from "react";
import Viewer from 'react-viewer';
import 'react-viewer/dist/index.css';


//component import
import reqDoc from "../assets/graphics/mock-ups.png"
import scPremiere from "../assets/graphics/SC Premiere.png";
import fomo from '../assets/posters/fomo.png';
import farFromHome from '../assets/posters/farFromHome.png';
import resurrection from '../assets/posters/resurrection.png';


class DesignsGraphics extends React.Component {
    constructor() {
        super();

        this.state = {
            visible: false,
        };
    }

    render() {
        return (
            <div>
                <button className={"graphicShowButton"} onClick={() => { this.setState({ visible: !this.state.visible }); } }>Show</button>
                <Viewer
                    visible={this.state.visible}
                    attribute={false}
                    noNavbar={true}
                    onClose={() => { this.setState({ visible: false }); } }
                    images={[
                        {src: reqDoc, alt: 'Pomelor'},
                        {src: scPremiere, alt: 'SC Premiere'},
                        {src: fomo, alt: 'Fomo'},
                        {src: resurrection, alt: 'Resurrection'},
                        {src: farFromHome, alt: 'Far From Home'},
                        ]}
                />
            </div>
        );
    }
}

export default DesignsGraphics;